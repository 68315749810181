import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom"
import styles from './cloudSummaries.module.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { api } from "../../../../Utils/API";
import Dropdown from "react-bootstrap/Dropdown";
import Form from 'react-bootstrap/Form';
import 'reactjs-popup/dist/index.css';
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import RaiseTicketIllustration from '../../../../Assets/Images/Illustrations/raiseticketillustration.svg'
import {
    GET_SERVICES_ALL,
    CLOUD_SUMMARY_LISTING_TABLE, CLOUD_SUMMARY_DOWNLOAD_CSV_SUMMARY, CLOUD_SUMMARY_DOWNLOAD_CSV_RECON,
    CLOUD_SUMMARY_DOWNLOAD_PDF_SUMMARY, CLOUD_SUMMARY_DOWNLOAD_PDF_SLIPS, CLOUD_SUMMARY_DOWNLOAD_DAILY_USAGE_CSV, GET_CUSTOMERS_LIST,
    RESELLER_API
} from '../../../../Utils/GlobalConstants';
import CloudSummaryFilterBox from './LocalComponents/FilterBox';
import Toast from '../../../../GlobalComponents/Toast';
import AccessDenied from '../../../../GlobalComponents/EmptyStates/AccessDenied';
import SummaryWidgets from './LocalComponents/Widgets';
import LazyTable from './LocalComponents/LazyTable';
import ResellerNavdropdown from '../../../../GlobalComponents/ResellerNavdropdown';

const CloudSummaryListing = (props) => {

    const navigate = useNavigate();                                                     // to use navigate function from react router dom  
    const location = useLocation();                                                     // to use Location
    const date = new Date();                                                            // for js date time function
    const [isDisabled, setIsDisabled] = useState(localStorage.getItem("disabled_array") && localStorage.getItem("disabled_array").includes("Billing") ? true : false);

    const searchParams = new URLSearchParams(location.search);
    const [searchIconFlag, setSearchIconFlag] = useState(false);                        // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState((searchParams.get('searchValueTable') && searchParams.get('searchValueTable') !== "null") ? searchParams.get('searchValueTable') : null);                     // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                                // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                          // showing filter box
    const searchInputRef = useRef();                                                    // Search Input Box
    const [serviceData, setServiceData] = useState(null);                             // data in service dropdown
  
    // Table Parameters
    const [pageNo, setPageNo] = useState(parseInt(searchParams.get('pageNo')) || 1);                                            // table active page number
    const [pageSize, setPageSize] = useState(parseInt(searchParams.get('pageSize')) || 15);                                        // table active page size
    const [totalRows, setTotalRows] = useState(0);                                      // table total count of data from api 
    const [columns, setColumns] = useState([]);                                         // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                             // data state used to display data in react data table component
    let ColumnName = ["Summary No.", "Cloud Service",                                   // table headers used to make object of react data table component headers
        "Summary Date", "Due Date", "Invoice Amount",
        "Credit Note Amount", "Balance Amount", "Actions"];
    const [initialLoading, setInitialLoading] = useState(true);                         // loader for table

    const [serverErrorCloudSummary, setServerErrorCloudSummary] = useState(false);      // server error check state in table during api call to load data
    const [emptyCloudSummary, setEmptyCloudSummary] = useState(false);                  // empty table check state in table during api call to load data
    const [emptyCloudSummaryFilters, setEmptyCloudSummaryFilters] = useState(false);    // empty filter response check state in table during api call to load data
    const [emptyCloudSummarySearch, setEmptyCloudSummarySearch] = useState(false);      // empty search response check state in table during api call to load data
    const [emptyCloudSummaryReseller, setEmptyCloudSummaryReseller] = useState(false);                  // empty table check state in table during api call to load data for reseller dropdown

    // Get Table Data Function Parameters
    const [serviceId, setServiceId] = useState(searchParams.get('serviceId') || null);                                   // getTableData() service id
    const [summaryDateFrom, setSummaryDateFrom] = useState(searchParams.get('summaryDateFrom') || null);                       // getTableData() summary from date
    const [summaryDateTo, setSummaryDateTo] = useState(searchParams.get('summaryDateTo') || null);                           // getTableData() summary to date
    const [dueDateFrom, setDueDateFrom] = useState(searchParams.get('dueDateFrom') || null);                               // getTableData() due from date
    const [dueDateTo, setDueDateTo] = useState(searchParams.get('dueDateTo') || null);                                   // getTableData() due to date
    const [sortCol, setSortCol] = useState(searchParams.get('sortCol') || "summaryNumber");                            // getTableData() sorting column name
    const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || "DESC");                                     // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending

    const [isDescSummaryNumber, setIsDescSummaryNumber] = useState(searchParams.get('sortCol') === "summaryNumber" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);               // sort state check for summary number column
    const [isDescSummaryDate, setIsDescSummaryDate] = useState(searchParams.get('sortCol') === "summaryDate" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                   // sort state check for summary date column
    const [isDescCloudService, setIsDescCloudService] = useState(searchParams.get('sortCol') === "serviceName" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                 // sort state check for cloud service column
    const [isDescDueDate, setIsDescDueDate] = useState(searchParams.get('sortCol') === "dueDate" ? (searchParams.get('sortDir') === "DESC" ? true : false) : null);                           // sort state check for due date column

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);
    const [infinityLoading, setInfinityLoading] = useState(false);                     // loading state while table loads for lazy loading

    // if custom was selected in due date and summary date in filter box
    const [summaryServiceLastUsedDate, setSummaryServiceLastUsedDate]
        = useState(searchParams.get('summaryServiceLastUsedDate') || "default");                                                      // service dropdown saved data after applying filters
    const [summaryDateLastUsedDate, setSummaryDateLastUsedDate]
        = useState(searchParams.get('summaryDateLastUsedDate') || "default");                                                      // summary date dropdown saved data after applying filters
    const [dueDateLastUsedDate, setDueDateLastUsedDate]
        = useState(searchParams.get('dueDateLastUsedDate') || "default");                                                      // due date dropdown saved data after applying filters 
    const [summaryDateCustomFromLast, setSummaryDateCustomFromLast]
        = useState(searchParams.get('summaryDateCustomFromLast') || date.toISOString().slice(0, 10));                                // summary from date saved data after applying filter having custom selection
    const [summaryDateCustomToLast, setSummaryDateCustomToLast]
        = useState(searchParams.get('summaryDateCustomToLast') || date.toISOString().slice(0, 10));                                // summary to date saved data after applying filter having custom selection
    const [dueDateCustomFromLast, setDueDateCustomFromLast]
        = useState(searchParams.get('dueDateCustomFromLast') || date.toISOString().slice(0, 10));                                // due from date saved data after applying filter having custom selection
    const [dueDateCustomToLast, setDueDateCustomToLast]
        = useState(searchParams.get('dueDateCustomToLast') || date.toISOString().slice(0, 10));                                // due from date saved data after applying filter having custom selection

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                            // toast message displaying success message 
    const [toastError, setToastError] = useState(false);                                // toast message displaying error message 
    const [toastMessage, setToastMessage] = useState("");                               // toast message usestate

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // reseller dropdown for  staff users
    const [resellerData, setResellerData] = useState([]);                            // data in customer dropdown
    const [resellerNameDefaultValue, setResellerNameDefaultValue] = useState(searchParams.get('resellerNameDefaultValue') || "Resellers");
    const [resellerNameSearchValue, setResellerNameSearchValue] = useState();
    const [resellerName, setResellerName] = useState([]);
    const [resellerNameFiltered, setResellerNameFiltered] = useState(resellerName);
    const updatedResellerName = [];
    const [resellerId, setResellerId] = useState(searchParams.get('resellerId') || "");
    const [resellerIdChangedFlag, setResellerIdChangedFlag] = useState(false);
    const [isCloudAdmin, setIsCloudAdmin] = useState(localStorage.getItem("curentRole") && localStorage.getItem("curentRole").toLowerCase().includes("cloud admin view") ? true : false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className={styles['threeDotAnchorTag']}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className={styles["threedots"]} />
        </a>
    ));

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF and CSV File from actions Dropdown
    async function DownloadAction(id, url, name, fileType) {
        let configuration;
        if (fileType === "pdf") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        // await api.get(url + id, configuration)
        await api.get(`${url + id}?requestedResellerCode=${resellerId}`, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    // console.log("Response : ", resp.data);
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                    // // console.log(d);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                    // console.log(error);
                }
            });
    };

    // // Function to call all dropdown values in Reseller Section in Filter Box
    async function GetAllResellers() {
        await api.get(RESELLER_API, config)
            .then(resp => {
                setResellerData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response.status == 401) {
                    props.Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetAllServices() {
        await api.get(GET_SERVICES_ALL, config)
            .then(resp => {
                setServiceData([{ id: "0", name: 'All Services', integrationCode: 'default' }, ...resp.data]);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                    // console.log(error);
                }
            });
    };

    // Get Table Data with Filters, Pagination, Search and Sort Functionality
    async function GetTableData(page, newPerPage, search, serviceId, summaryDateFrom, summaryDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, appendData, isFilter) {
       console.log("Vishwajit")
        setInitialLoading(false);
        (search === undefined || search === null || search === "null") ? search = "" : search = search;
        (serviceId === undefined || serviceId === null || serviceId === "null") ? serviceId = "" : serviceId = serviceId;
        (summaryDateFrom === undefined || summaryDateFrom === null || summaryDateFrom === "null") ? summaryDateFrom = "" : summaryDateFrom = summaryDateFrom;
        (summaryDateTo === undefined || summaryDateTo === null || summaryDateTo === "null") ? summaryDateTo = "" : summaryDateTo = summaryDateTo;
        (dueDateFrom === undefined || dueDateFrom === null || dueDateFrom === "null") ? dueDateFrom = "" : dueDateFrom = dueDateFrom;
        (dueDateTo === undefined || dueDateTo === null || dueDateTo === "null") ? dueDateTo = "" : dueDateTo = dueDateTo;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;
        await api.get(`${CLOUD_SUMMARY_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&summaryNumber=${search}&serviceId=${serviceId}&fromDate=${summaryDateFrom}&toDate=${summaryDateTo}&fromDueDate=${dueDateFrom}&toDueDate=${dueDateTo}&sortCol=${sortCol}&sortDir=${sortDir}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setInitialLoading(true);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    if (search === "" && serviceId === "" && summaryDateFrom === "" && summaryDateTo === "" && dueDateFrom === "" && dueDateTo === "" && resellerId === "") {
                        setEmptyCloudSummary(true);
                    } else if ((serviceId != "" || summaryDateFrom != "" || summaryDateTo != "" || dueDateFrom != "" || dueDateTo != "" || resellerId != "") && isFilter) {
                        setEmptyCloudSummaryFilters(true);
                    } else if (search != "" && !isFilter) {
                        setEmptyCloudSummarySearch(true);
                    } else {
                        setEmptyCloudSummaryReseller(true);
                    }
                    // isFilter = filterFlag ? true : false
                    // if (search === "" && ((serviceId === "" && summaryDateFrom === "" && summaryDateTo === "" && dueDateFrom === "" && dueDateTo === "" || resellerId === "") || (serviceId.length === 0 && summaryDateFrom.length === 0 && summaryDateTo.length === 0 && dueDateFrom.length === 0 && dueDateTo.length === 0 && resellerId.length === 0) )) {
                    //     setShowFilterBox(false);
                    //     setEmptyCloudSummary(true);
                    // }
                    // else if ((serviceId != "" || summaryDateFrom != "" || summaryDateTo != "" || dueDateFrom != "" || dueDateTo != "" || resellerId != "") && isFilter) {
                    //     setEmptyCloudSummaryFilters(true);
                    //                     }
                    // else if (search != "" && !isFilter) {
                    //     setEmptyCloudSummarySearch(true);
                    // }
                }
                // handle success
                else {
                    setServerErrorCloudSummary(false);
                    setEmptyCloudSummary(false);
                    setEmptyCloudSummaryFilters(false);
                    setEmptyCloudSummarySearch(false);
                    setEmptyCloudSummaryReseller(false);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        f.push
                            (
                                {
                                    "Summary No.": <span className={styles["cloud-summary-color-underline"]} onClick={() => { navigate("/billing/cloud-summary/summary-detail", { state: { Id: td.id, summaryNumber: td.summaryNumber, resellerId: resellerId }, }); }} >{td.summaryNumber}</span>, //"PICT00000235",
                                    "Cloud Service": `${td.serviceName}`,
                                    "Summary Date": td.summaryDate,
                                    "Due Date": td.dueDate,
                                    "Invoice Amount": td.invoiceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.invoiceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.invoiceAmount)).toFixed(2))}`,
                                    "Credit Note Amount": td.creditNoteAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.creditNoteAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.creditNoteAmount)).toFixed(2))}`,
                                    "Balance Amount": td.balanceAmount >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((td.balanceAmount).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(td.balanceAmount)).toFixed(2))}`,
                                    "Id": td.id,
                                    "Actions": <Dropdown drop={"start"}>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title="">
                                            <Dropdown.Item onClick={(e) => { DownloadAction(td.summaryNumber, CLOUD_SUMMARY_DOWNLOAD_PDF_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.summaryNumber}_summary.pdf`, "pdf") }} id="1">Download PDF</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { DownloadAction(td.summaryNumber, CLOUD_SUMMARY_DOWNLOAD_CSV_SUMMARY, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.summaryNumber}_summary.csv`, "csv") }} id="1">Download CSV</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { DownloadAction(td.summaryNumber, CLOUD_SUMMARY_DOWNLOAD_PDF_SLIPS, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.summaryNumber}_summaryslip.pdf`, "pdf") }} id="1">Download Slips</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => { DownloadAction(td.summaryNumber, CLOUD_SUMMARY_DOWNLOAD_CSV_RECON, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.summaryNumber}_reconfile.csv`, "csv") }} id="1">Download Reconciliation</Dropdown.Item>
                                            {td.serviceName == "Microsoft CSP - Azure" ?
                                                <Dropdown.Item onClick={(e) => { DownloadAction(td.summaryNumber, CLOUD_SUMMARY_DOWNLOAD_DAILY_USAGE_CSV, `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, "0")}${date.getFullYear()}_${td.summaryNumber}_dailyUsage.csv`, "csv") }} id="1">Usage CSV</Dropdown.Item> : ""
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            );
                    })
                    // console.log("Test F", f);
                    setTimeout(() => {
                        if (appendData) {
                            setTable(table => [...table, ...f])            // Setting Table Data
                        } else {
                            setTable(f)
                        }
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Actions") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                center: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Summary No.") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {(isDescSummaryNumber === null && sortCol !== "summaryNumber") && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescSummaryNumber(true); setSortCol("summaryNumber"); setIsDescSummaryDate(null); setIsDescCloudService(null); setIsDescDueDate(null); }}></span></>}
                                    {((isDescSummaryNumber === null && sortCol === "summaryNumber" && sortDir === "DESC") || isDescSummaryNumber === true) && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescSummaryNumber(false); setSortCol("summaryNumber"); }}></span></>}
                                    {isDescSummaryNumber === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescSummaryNumber(true); setSortCol("summaryNumber"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                        else if (td === "Summary Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescSummaryDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescSummaryDate(true); setSortCol("summaryDate"); setIsDescSummaryNumber(null); setIsDescCloudService(null); setIsDescDueDate(null); }}></span></>}
                                    {isDescSummaryDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescSummaryDate(false); setSortCol("summaryDate"); }}></span></>}
                                    {isDescSummaryDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescSummaryDate(true); setSortCol("summaryDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1.3,
                            })
                        }
                        else if (td === "Cloud Service") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescCloudService === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCloudService(true); setSortCol("serviceName"); setIsDescSummaryDate(null); setIsDescSummaryNumber(null); setIsDescDueDate(null); }}></span></>}
                                    {isDescCloudService === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescCloudService(false); setSortCol("serviceName"); }}></span></>}
                                    {isDescCloudService === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescCloudService(true); setSortCol("serviceName"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 1.2,
                            })
                        }
                        else if (td === "Due Date") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDescDueDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescDueDate(true); setSortCol("dueDate"); setIsDescSummaryDate(null); setIsDescSummaryNumber(null); setIsDescCloudService(null); }}></span></>}
                                    {isDescDueDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescDueDate(false); setSortCol("dueDate"); }}></span></>}
                                    {isDescDueDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescDueDate(true); setSortCol("dueDate"); }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                                grow: 0.8,
                            })
                        }
                        else if (td === "Invoice Amount") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                                grow: 1.2,
                            })
                        }
                        else if (td === "Credit Note Amount") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                                grow: 1.5,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-right">{row[`${td}`]}</div>,
                                right: true,
                                grow: 1.2,
                            })
                        }

                    })
                    setColumns(d);       // Setting Column Data
                    // console.log("Reached");
                    // console.log(d);
                }
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                    setServerErrorCloudSummary(true);
                    // console.log(error);
                }
            });
    };

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
        if (searchValueTable === "null") {
            setSearchValueTable(null);
        }
        if (searchValueTable !== undefined && searchValueTable !== null) {
            const getData = setTimeout(() => {
                if (searchValueTable.length > 0) {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setResetPaginationToggle(true);
                    GetTableData(1, pageSize, searchValueTable, serviceId, summaryDateFrom, summaryDateTo, dueDateFrom, dueDateTo, sortCol, sortDir);
                    setResetPaginationToggle(false);
                } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setResetPaginationToggle(true);
                    GetTableData(1, pageSize, searchValueTable, serviceId, summaryDateFrom, summaryDateTo, dueDateFrom, dueDateTo, sortCol, sortDir);
                    setResetPaginationToggle(false);
                }
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable])


    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, serviceId, summaryDateFrom, summaryDateTo, dueDateFrom, dueDateTo, sortCol, sortDir, true))();

            return newPageNumber;
        });
    }

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '400px'
            },
        },
        rows: {
            style: {
                minHeight: '60px',       // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',      // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px',    // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                // textAlign: 'center',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, serviceId, summaryDateFrom, summaryDateTo, dueDateFrom, dueDateTo, sortCol, sortDir);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, serviceId, summaryDateFrom, summaryDateTo, dueDateFrom, dueDateTo, sortCol, sortDir);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, serviceId, summaryDateFrom, summaryDateTo, dueDateFrom, dueDateTo, sortCol, sortDir);
    }, [isDescSummaryNumber, isDescSummaryDate, isDescCloudService, isDescDueDate])

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    // Use Effect to get all the services in filter service dropdown
    useEffect(() => {
        localStorage.getItem("navigation_link") ? localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link", "/billing/cloud-summary");
        GetAllServices();
        { isCloudAdmin && GetAllResellers(); }
    }, []);

    // useEffct to select reseller for staff user
    useEffect(() => {
        if(resellerId && resellerId !== "null" && resellerIdChangedFlag){
            setSearchValueTable(null);
            setServiceId(null);
            setSummaryDateTo(null);
            setSummaryDateFrom(null);
            setDueDateFrom(null);
            setDueDateTo(null);
            setPageNo(1);

            if (summaryDateLastUsedDate !== "default") {
                setSummaryDateLastUsedDate("default");
                const summaryElement = document.getElementById(summaryDateLastUsedDate);
                if (summaryElement) {
                    summaryElement.checked = false; // Uncheck the checkbox if it exists
                }
            }
            
            if (dueDateLastUsedDate !== "default") {
                setDueDateLastUsedDate("default");
                const dueElement = document.getElementById("due_date_" + dueDateLastUsedDate);
                if (dueElement) {
                    dueElement.checked = false; // Uncheck the checkbox if it exists
                }
            }
            setSummaryDateCustomFromLast(date.toISOString().slice(0, 10));
            setDueDateCustomFromLast(date.toISOString().slice(0, 10));
            setDueDateCustomToLast(date.toISOString().slice(0, 10));
            setSummaryDateCustomToLast(date.toISOString().slice(0, 10));
            setFilterFlag(false);
            setResellerIdChangedFlag(false);
            GetTableData(1, pageSize, null, null, null, null, null, null, sortCol, sortDir, false);
        }
    }, [resellerId]);

    useEffect(() => {
        const newSearchParams = new URLSearchParams();
        newSearchParams.set('pageNo', pageNo.toString());
        newSearchParams.set('pageSize', pageSize.toString());
        newSearchParams.set('sortCol', sortCol);
        newSearchParams.set('sortDir', sortDir);
        newSearchParams.set('searchValueTable', searchValueTable);
        newSearchParams.set('serviceId', serviceId);
        newSearchParams.set('resellerId', resellerId);
        newSearchParams.set('resellerNameDefaultValue', resellerNameDefaultValue);
        newSearchParams.set('summaryDateFrom', summaryDateFrom);
        newSearchParams.set('summaryDateTo', summaryDateTo);
        newSearchParams.set('dueDateFrom', dueDateFrom);
        newSearchParams.set('dueDateTo', dueDateTo);
        newSearchParams.set('summaryServiceLastUsedDate', summaryServiceLastUsedDate);
        newSearchParams.set('summaryDateLastUsedDate', summaryDateLastUsedDate);
        newSearchParams.set('dueDateLastUsedDate', dueDateLastUsedDate);
        newSearchParams.set('summaryDateCustomFromLast', summaryDateCustomFromLast);
        newSearchParams.set('summaryDateCustomToLast', summaryDateCustomToLast);
        newSearchParams.set('dueDateCustomFromLast', dueDateCustomFromLast);
        newSearchParams.set('dueDateCustomToLast', dueDateCustomToLast);

        window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    }, [pageNo, pageSize, sortDir, sortCol, searchValueTable, serviceId, summaryDateFrom, summaryDateTo, dueDateFrom, dueDateTo,
        summaryServiceLastUsedDate, summaryDateLastUsedDate, summaryDateCustomFromLast, summaryDateCustomToLast, dueDateCustomFromLast, dueDateCustomToLast, resellerId]);

    useEffect(() => {
        (searchParams.get('searchValueTable') && searchParams.get('searchValueTable').length > 0 && searchParams.get('searchValueTable') !== "null") ? setSearchIconFlag(true) : setSearchIconFlag(false);
        (
            (searchParams.get('serviceId') || searchParams.get('summaryDateFrom') || searchParams.get('summaryDateTo') || searchParams.get('dueDateFrom') || searchParams.get('dueDateTo')) &&
                (searchParams.get('serviceId') !== "null" || searchParams.get('summaryDateFrom') !== "null" || searchParams.get('summaryDateTo') !== "null" || searchParams.get('dueDateFrom') !== "null" || searchParams.get('dueDateTo') !== "null") &&
                (searchParams.get('serviceId').length > 0 || searchParams.get('summaryDateFrom').length > 0 || searchParams.get('summaryDateTo').length > 0 || searchParams.get('dueDateFrom').length > 0 || searchParams.get('dueDateTo').length > 0)
                ? setFilterFlag(true) : setShowFilterBox(false)
        );
    }, [])


    return (
        <>
            <div className="main-content">
                <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
                <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
                {!isDisabled && <div className="customer-management-console-main-div mb-5">
                    <div className="mgmt-console-table-row">
                        <Container fluid>
                            <h1 className="py-1">Summaries</h1>
                            {isCloudAdmin && <Col md={3}>
                                <ResellerNavdropdown
                                    formGroupId="customerNameId"
                                    placeholder="Enter Reseller Code"
                                    selectDefaultValue={resellerNameDefaultValue}
                                    setSelectDefaultValue={setResellerNameDefaultValue}
                                    setOptionId={setResellerId}
                                    selectOptions={resellerData}
                                    selectOptionsFiltered={resellerNameFiltered}
                                    setSelectOptionsFiltered={setResellerNameFiltered}
                                    selectSearchValue={resellerNameSearchValue}
                                    setSelectSearchValue={setResellerNameSearchValue}
                                    updatedOptions={updatedResellerName}
                                    setResellerIdChangedFlag={setResellerIdChangedFlag}
                                />
                            </Col>}
                            <SummaryWidgets Logout={props.Logout} requestedResellerCode={resellerId} />
                        </Container>
                        <div className="my-3 d-flex">
                            <Col className={`py-0 overflow-auto ${styles['cloud-summary-table']}`}>
                                <div className={`${styles['cloud-summary-table-inner']} ${styles['table-details']} details-box`}>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                            <h5 className="py-1 pl-l mb-0 mobile-padding-search-open subscription-heading-table-header">Summaries {!emptyCloudSummary && !emptyCloudSummaryFilters && !emptyCloudSummarySearch && !emptyCloudSummaryReseller && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h5>
                                        </Col>
                                        {/* Desktop and tab view search and filter*/}
                                        {<Col className="px-1 d-none d-md-block">
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub px-2">
                                                {!serverErrorCloudSummary && !emptyCloudSummary && !emptyCloudSummaryReseller && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                    {!searchIconFlag &&
                                                        <span className="mx-3">&nbsp;</span>
                                                    }
                                                    {!searchIconFlag && !emptyCloudSummaryFilters &&
                                                        <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                    }
                                                    {searchIconFlag && !emptyCloudSummaryFilters && !emptyCloudSummaryReseller &&
                                                        <InputGroup className="search-input-box">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search by Summary No."
                                                                aria-label="Search by Summary No."
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                                ref={searchInputRef}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    }

                                                    {!filterFlag && !emptyCloudSummarySearch && !emptyCloudSummaryReseller && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                    }}></span>}
                                                    {initialLoading && filterFlag && !emptyCloudSummarySearch && !emptyCloudSummaryReseller && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                </Col>}
                                            </Row>
                                        </Col>}
                                        {/* mobile view search and filter */}
                                        {<Col className="px-1 d-block d-md-none" md={12}>
                                            <Row className="justify-content-start align-items-center mobile-padding-search-open-sub px-2">
                                                {!serverErrorCloudSummary && !emptyCloudSummary && <Col className="d-flex align-items-center justify-items-center justify-content-between position-relative">
                                                    {!emptyCloudSummaryFilters && !emptyCloudSummaryReseller &&
                                                        <InputGroup className="search-input-box">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search by Summary No."
                                                                aria-label="Search by Summary No."
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    }

                                                    {!filterFlag && !emptyCloudSummarySearch && !emptyCloudSummaryReseller && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                    }}></span>}
                                                    {initialLoading && filterFlag && !emptyCloudSummarySearch && !emptyCloudSummaryReseller && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                </Col>}
                                            </Row>
                                        </Col>}
                                    </div>
                                    {
                                        !emptyCloudSummarySearch && !emptyCloudSummaryReseller && showFilterBox &&
                                        <CloudSummaryFilterBox serviceId={serviceId} setServiceId={setServiceId} GetTableData={GetTableData} pageNo={pageNo} setPageNo={setPageNo}
                                            pageSize={pageSize} searchValueTable={searchValueTable} summaryDateFrom={summaryDateFrom} summaryDateTo={summaryDateTo}
                                            setSummaryDateFrom={setSummaryDateFrom} setSummaryDateTo={setSummaryDateTo} dueDateFrom={dueDateFrom}
                                            setDueDateFrom={setDueDateFrom} dueDateTo={dueDateTo} setDueDateTo={setDueDateTo} sortCol={sortCol} sortDir={sortDir}
                                            setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} summaryServiceLastUsedDate={summaryServiceLastUsedDate}
                                            setSummaryServiceLastUsedDate={setSummaryServiceLastUsedDate} summaryDateLastUsedDate={summaryDateLastUsedDate}
                                            setSummaryDateLastUsedDate={setSummaryDateLastUsedDate} dueDateLastUsedDate={dueDateLastUsedDate} setDueDateLastUsedDate={setDueDateLastUsedDate}
                                            summaryDateCustomFromLast={summaryDateCustomFromLast} setSummaryDateCustomFromLast={setSummaryDateCustomFromLast} summaryDateCustomToLast={summaryDateCustomToLast}
                                            setSummaryDateCustomToLast={setSummaryDateCustomToLast} dueDateCustomFromLast={dueDateCustomFromLast} setDueDateCustomFromLast={setDueDateCustomFromLast}
                                            dueDateCustomToLast={dueDateCustomToLast} setDueDateCustomToLast={setDueDateCustomToLast} customerIsAuthenticated={props.customerIsAuthenticated} setCustomerIsAuthenticated={props.setAuthentication}
                                            serviceData={serviceData} GetAllServices={GetAllServices} setResetPaginationToggle={setResetPaginationToggle} setScrollToDivTop={setScrollToDivTop}
                                        />
                                    }
                                    <Container fluid className="mb-5 p-0">
                                        <Row>
                                            <Col>
                                                <div className={`table-responsive ${styles['cloud-summary-history-table']}`}>
                                                    {initialLoading && serverErrorCloudSummary &&
                                                        <div className={styles["no-data-cloud-summary"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-summary-table-svg" />
                                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyCloudSummarySearch &&
                                                        <div className={styles["no-data-cloud-summary"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-summary-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Summaries Found</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyCloudSummaryReseller && !emptyCloudSummaryFilters && !emptyCloudSummarySearch && !emptyCloudSummary &&
                                                        <div className={styles["no-data-cloud-summary"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-summary-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Summaries Found</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyCloudSummaryFilters && !emptyCloudSummarySearch &&
                                                        <div className={styles["no-data-cloud-summary"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-cloud-summary-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Summaries Found</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyCloudSummary &&
                                                        <div className={styles["no-data-cloud-summary"]}>
                                                            <img src={RaiseTicketIllustration} className="empty-cloud-summary-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Summaries Found.</h2>
                                                        </div>
                                                    }
                                                    {!serverErrorCloudSummary && !emptyCloudSummaryFilters && !emptyCloudSummary && !emptyCloudSummarySearch &&
                                                        <LazyTable
                                                            d={columns}
                                                            table={table}
                                                            customStyles={customStyles}
                                                            loading={infinityLoading}
                                                            pageNo={pageNo}
                                                            totalRows={totalRows}
                                                            handlePerRowsChange={handlePerRowsChange}
                                                            handlePageChange={handlePageChange}
                                                            styles={styles}
                                                            handleLazyCalls={handleLazyCalls}
                                                            serverErrorUser={serverErrorCloudSummary}
                                                            emptyUser={emptyCloudSummary}
                                                            emptyUserFilters={emptyCloudSummaryFilters}
                                                            emptyUserSearch={emptyCloudSummarySearch}
                                                            emptyCloudSummaryReseller={emptyCloudSummaryReseller}
                                                            setScrollToDivTop={setScrollToDivTop}
                                                            scrollToDivTop={scrollToDivTop}
                                                        />}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>}
                {
                    isDisabled && <AccessDenied />
                }
            </div>
        </>
    )
}

export default CloudSummaryListing