import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import "./styles.scss";
import { GET_SERVICES, ACRONIS_CONVERT_TRIAL_TO_PAID, GET_CUSTOMERS_LIST, ACRONIS_SERVICE_MANAGEMENT_ACCOUNT_DETAILS, ACRONIS_SERVICE_MANAGEMENT_PRICE_LIST, ACRONIS_SERVICE_MANAGEMENT_UPGRADE_VALUES } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Form from "react-bootstrap/Form";
import { Container, Button, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import Toast from '../../../../../GlobalComponents/Toast';
import serviceLogo from '../../../../../Assets/Images/Illustrations/acronis.svg';
import SearchDropdown from "../../../../../GlobalComponents/SearchDropdown";
import InactiveServiceSubscription from '../../../../../Assets/Images/Illustrations/EmptyServiceProvision.svg'
import EmptyServiceProvision from '../../../../../Assets/Images/Illustrations/EmptyServiceProvision.svg';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';

const AcronisServiceManagement = ({ toggleDrawer, setSelectService, setSelectCategory, Logout }) => {
    const location = useLocation();                                                  // use to access data from other page data
    const navigate = useNavigate();                                                  // to use navigate function from react router dom
    const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Manage") ? true : false);
    const [accountId, setAccountId] = useState(null);                                // storing account id
    const [customerId, setCustomerId]                                                // storing customer id 
        = useState(location.state !== null ? location.state.customerId : "");
    const [resellerId, setResellerId]
        = useState((location.state !== null && location.state !== "") ? location.state.resellerId : "");  // storing reseller id
    const [serviceId, setServiceId] = useState(null);                                   // used for service dropdown names at the top
    const [serviceData, setServiceData] = useState(null);                             // data in service dropdown
    const [customerData, setCustomerData] = useState([]);                             // data in customer dropdown
    const [upgradeValuesData, setUpgradeValuesData] = useState(null);                 // data in status dropdown
    const [modalOpen, setModalOpen] = useState(false);                                //For modal opening
    const [additionalDetails, setAdditionalDetails] = useState(null);
    const [commitmentAmount, setCommitmentAmount] = useState(null);                   //storing commitmentAmount value
    const [nepheleStatus, setNepheleStatus] = useState(null);                         //storing nephelestatus value
    const [upgradeUpdatedValues, setUpgradeUpdatedValues] = useState();
    const [isTrial, setIsTrial] = useState(null);                                     //checking isTrial status
    const [isNotProvisioned, setIsNotProvisioned] = useState(null);
    const [isServerError, setIsServerError] = useState(false);

    // Search Value for Service Name
    const [serviceNameDefaultValue, setServiceNameDefaultValue] = useState("Select Service Name");
    const [serviceNameSearchValue, setServiceNameSearchValue] = useState();
    const [serviceName, setServiceName] = useState([]);
    const [serviceNameFiltered, setServiceNameFiltered] = useState(serviceName);
    const updatedServiceName = [];

    // Search Value for Customer Name
    const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState("Select Customer Name");
    const [customerNameSearchValue, setCustomerNameSearchValue] = useState();
    const [customerName, setCustomerName] = useState([]);
    const [customerNameFiltered, setCustomerNameFiltered] = useState(customerName);
    const updatedCustomerName = [];

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const [upgradePlanFlag, setUpgradePlanFlag] = useState(false);
    const [convertPlanFlag, setConvertPlanFlag] = useState(false);

    const [processingButtonFlag, setProcessingButtonFlag] = useState(false);          // Processing Button Flag
    const [actionCompletedRefreshSubscriptionTable, setActionCompletedRefreshSubscriptionTable] = useState(false);
    const defaultServiceName = "Acronis Cyber Cloud";
    const defaultCategoryName = "Cloud Technical Support";

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetAllServices() {

        await api.get(GET_SERVICES, config)
            .then(resp => {
                let serviceArray = []
                resp.data.map((service) => {
                    if (service.serviceType == "ONLINE") {
                        serviceArray.push(service);
                    }
                })
                setServiceData(serviceArray);
                setServiceNameFiltered(serviceArray);

                for (let i in resp.data) {
                    if (resp.data[i].integrationCode === "acronis1") {
                        setServiceId(resp.data[i].id);
                        setServiceNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }
                for (let i in resp.data) {
                    if (!serviceName.includes(resp.data[i].name)) {
                        serviceName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(`${GET_CUSTOMERS_LIST}?requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setCustomerData(resp.data);
                setCustomerNameFiltered(resp.data);

                for (let i in resp.data) {
                    if (resp.data[i].id === customerId) {
                        setCustomerNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }

                for (let i in resp.data) {
                    if (!customerName.includes(resp.data[i].name)) {
                        customerName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function to call all upgrade values
    async function GetAllUpgradeValues() {
        await api.get(`${ACRONIS_SERVICE_MANAGEMENT_UPGRADE_VALUES}?accountId=${accountId}&customerId=${customerId}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setUpgradeValuesData([]);
                    setIsServerError(false);
                }
                else {
                    setUpgradeValuesData(resp.data);
                    setIsServerError(false);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setIsServerError(true);
                }
            });
    };

    // function to call all additional details of order history
    async function GetAdditionalDetails() {
        await api.get(`${ACRONIS_SERVICE_MANAGEMENT_ACCOUNT_DETAILS}?customerId=${customerId}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                if (resp.data.isProvision === false) {
                    setIsNotProvisioned(true);
                    setNepheleStatus(null);
                }
                else {
                    setIsNotProvisioned(false);
                    setAdditionalDetails(resp.data);
                    setAccountId(resp.data.accountId);
                    setCommitmentAmount(resp.data.commitmentAmount);
                    setNepheleStatus(resp.data.nepheleStatus);
                    setIsTrial(resp.data.isTrial);
                }

            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setIsNotProvisioned(false);
                }
            });
    };

    // Header Authorization for Download PDF API having response type as blob
    const configBlob = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob',
    };

    // Download File using Js blobs
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        if (fileType === "application/pdf") {
            a.href = window.URL.createObjectURL(data)
        }
        else {
            a.href = window.URL.createObjectURL(blob)
        }
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    // Download PDF and CSV File 
    async function DownloadAction(url, name, fileType) {
        let configuration;
        if (fileType === "pdf") {
            configuration = configBlob;
        }
        else {
            configuration = config;
        }
        await api.get(url, configuration)
            .then(resp => {
                // handle success
                if (resp.status === 204) {
                    setToastMessage("No Data available to Download !");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
                else {
                    downloadFile({
                        data: (resp.data),
                        fileName: name,
                        fileType: `application/${fileType}`,
                    });
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    function CancelUpgradeSubscription() {
        var myCheckbox = document.getElementsByName("myCheckbox");
        Array.prototype.forEach.call(myCheckbox, function (el) {
            el.checked = false;
        });
        setUpgradeUpdatedValues(null);
    }

    // Convert Trial to Paid Plan
    async function ConvertTrialToPaid() {
        setProcessingButtonFlag(true);
        let body = {
            "accountId": accountId,
        }
        await api.put(ACRONIS_CONVERT_TRIAL_TO_PAID, body, config)
            .then(resp => {
                setProcessingButtonFlag(false);
                setConvertPlanFlag(false);
                setActionCompletedRefreshSubscriptionTable(true);
                setToastMessage("The Trial Plan has been successfully converted to a Paid Plan.");
                setToastSuccess(true);
                setTimeout(() => {
                    setToastSuccess(false);
                }, 2000);
                GetAdditionalDetails();
                GetAllUpgradeValues();
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setProcessingButtonFlag(false);
                    setToastMessage("Trial Plan could not be converted to Paid Plan at the moment, try again later.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
            });
    };

    // upgrade commitment value
    async function UpgradeCommitmentValue() {
        setProcessingButtonFlag(true);
        let body = {
            "accountId": accountId,
            "commitmentAmount": upgradeUpdatedValues
        }
        await api.put(ACRONIS_SERVICE_MANAGEMENT_UPGRADE_VALUES, body, config)
            .then(resp => {
                setProcessingButtonFlag(false);
                setUpgradePlanFlag(false);
                setActionCompletedRefreshSubscriptionTable(true);
                setToastMessage("Commitment Value Upgraded Successfully");
                setToastSuccess(true);
                setTimeout(() => {
                    setToastSuccess(false);
                }, 2000);
                GetAdditionalDetails();
                GetAllUpgradeValues();
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setProcessingButtonFlag(false);
                    setToastMessage("Commitment Value could not be Upgraded at the moment, try again later.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
            });
    };

    // Use Effect to get all the services in filter service dropdown
    useEffect(() => {
        GetAdditionalDetails();
        GetAllServices();
        GetAllCustomers();
    }, [customerId]);

    useEffect(() => {
        if (accountId !== null) {
            GetAllUpgradeValues();
        }
    }, [accountId, customerId])

    // Refreshing Subscription Table on Action Completion
    useEffect(() => {
        if (actionCompletedRefreshSubscriptionTable) {
            CancelUpgradeSubscription();
            GetAdditionalDetails();
            if (accountId !== null) {
                GetAllUpgradeValues();
            }
            setActionCompletedRefreshSubscriptionTable(false);
        }
    }, [actionCompletedRefreshSubscriptionTable])

    //Scroll to top on opening of page
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Renders when service ID Changes
    useEffect(() => {
        if (serviceId) {
            let url = "";
            let integrationCode = "";
            serviceData && serviceData.map((data) => {
                if (data.id == serviceId) {
                    integrationCode = data.integrationCode
                }
            })
            switch (integrationCode) {
                case "softlayer": // IBM Cloud
                    navigate("/management-console/ibm", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "microsoftazure": // Microsoft CSP - Azure
                    navigate("/management-console/azure", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "cloudcare": // Avast Cloud Care
                    navigate("/management-console/avast", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "acronis1": //  Acronis Cyber Cloud
                    navigate("/management-console/acronis", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "googleworkspace": // Google Workspace
                    navigate("/management-console/gws", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "GCP":  // Google Cloud Platform
                    navigate("/management-console/gcp", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                default: // Microsoft CSP
                    navigate("/management-console/csp", { state: { customerId: customerId, resellerId: resellerId } });
                    break;
            }
        }
    }, [serviceId]);

    return (
        <div className='main-content'>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
            <div className='container-fluid'>
                <Row>
                    <Col md={6} className="justify-content-between d-flex align-items-center">
                        <label className="cpointer" onClick={() => navigate('/management-console' , { state: { resellerId: resellerId } })}>
                            <span className="back">&nbsp;</span>&nbsp;<strong><span className="service-management-heading">Acronis Cyber Cloud - Service Management</span></strong>
                        </label>
                    </Col>
                    <Col md={6} className="text-right service-logo">
                        <img src={serviceLogo} />
                    </Col>
                </Row>
                <Popup
                    open={modalOpen}
                    onClose={() => { setModalOpen(false); }}
                    className="custom-modal custom-modal-md custom-modal-ticket-details"
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header">
                                <h2>Cloud Portal Details</h2>
                                <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={close}
                                />
                            </div>
                            <Container>

                                {additionalDetails && <Row>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Cloud Service</label>
                                        <h3 className="mb-3">Acronis Cyber Cloud</h3>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">User Name</label>
                                        <p><strong>{additionalDetails.username}</strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Service Start Date</label>
                                        <p><strong>{additionalDetails.serviceStartDate}</strong></p>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <label className="text-medium">Plan Name</label>
                                        <p><strong>{additionalDetails.planName}</strong></p>
                                    </Col>
                                    <Col md={12} className="mb-2">
                                        <label className="text-medium">Portal</label>
                                        <p className='service-management-acronis-portal-link'><a target='_blank' href={additionalDetails.serviceUrl}>{additionalDetails.serviceUrl} <span className='external-link cpointer ml-2'></span></a></p>
                                    </Col>
                                    {additionalDetails.isTrial &&
                                        <>
                                            <Col md={6} className="mb-2">
                                                <label className="text-medium">Trial</label>
                                                <p><strong>{additionalDetails.isTrial ? "Yes" : "No"}</strong></p>
                                            </Col>
                                            <Col md={6} className="mb-2">
                                                <label className="text-medium">Trial End Date</label>
                                                <p><strong>{additionalDetails.trialEndDate}</strong></p>
                                            </Col>
                                        </>
                                    }
                                    <Col md={12} className="mb-2">
                                        <label className="text-medium">Vendor ID</label>
                                        <p><strong>{additionalDetails.vendorAccountId}</strong></p>
                                    </Col>

                                </Row>}
                            </Container>
                        </div>
                    )}
                </Popup>
                <Popup
                    className="custom-modal custom-modal-md"
                    open={upgradePlanFlag}
                    onClose={() => setUpgradePlanFlag(false)}
                    closeOnDocumentClick={!processingButtonFlag}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header py-3 px-3">
                                <h2 className="mb-0">Upgrade Plan
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip className='upgrade-acronis-tooltip'>Note: Changing the commitment tier on first and last two days of the month will not reflect in current month billing.</Tooltip>}
                                    >
                                        <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                    </OverlayTrigger>
                                </h2>
                                <XLg
                                    size={18}
                                    className="cpointer text-strong close-icon-popup-sm"
                                    onClick={() => { if (!processingButtonFlag) close() }}
                                    color="#6A6A6A"
                                />
                            </div>
                            <Container className="p-5">
                                <p className='text-break'>
                                    Are you sure you are upgrading to $ {upgradeUpdatedValues} Monthly Commitment plan?
                                </p>
                                <div className="actions">
                                    {!processingButtonFlag && <div className="d-flex justify-content-end pt-2 text-right">
                                        <Button
                                            className="btn btn-md mx-3"
                                            onClick={() => {
                                                UpgradeCommitmentValue();
                                            }}
                                        >
                                            Yes
                                        </Button>

                                        <Button
                                            className="btn btn-lg btn-border-light"
                                            variant="light"
                                            onClick={() => {
                                                close();
                                            }}
                                        >
                                            No
                                        </Button>
                                    </div>}
                                    {processingButtonFlag &&
                                        <div className="d-flex justify-content-end pt-2 text-right">
                                            <Button
                                                className="btn btn-md mx-3"
                                                disabled
                                            >
                                                Processing . . .
                                            </Button>

                                            <Button
                                                className="btn btn-lg btn-border-light"
                                                variant="light"
                                                disabled
                                            >
                                                No
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </Container>
                        </div>
                    )}
                </Popup>

                {/* Convert to Paid Plan */}
                <Popup
                    className="custom-modal custom-modal-md"
                    open={convertPlanFlag}
                    onClose={() => setConvertPlanFlag(false)}
                    closeOnDocumentClick={!processingButtonFlag}
                    modal
                    nested
                >
                    {(close) => (
                        <div className="modal">
                            <div className="header py-3 px-3">
                                <h2 className="mb-0">Convert to Paid Plan</h2>
                                <XLg
                                    size={18}
                                    className="cpointer text-strong close-icon-popup-sm"
                                    onClick={() => { if (!processingButtonFlag) close() }}
                                    color="#6A6A6A"
                                />
                            </div>
                            <Container className="p-5">
                                <p className='text-break'>
                                    You are upgrading to a paid account, you would not be able to switch back to Trial mode. After upgrade ${commitmentAmount} Monthly Commitment will apply. Please press Yes to proceed.
                                </p>
                                <div className="actions">
                                    {!processingButtonFlag && <div className="d-flex justify-content-end pt-2 text-right">
                                        <Button
                                            className="btn btn-md mx-3"
                                            onClick={() => {
                                                ConvertTrialToPaid();
                                            }}
                                        >
                                            Yes
                                        </Button>

                                        <Button
                                            className="btn btn-lg btn-border-light"
                                            variant="light"
                                            onClick={() => {
                                                close();
                                            }}
                                        >
                                            No
                                        </Button>
                                    </div>}
                                    {processingButtonFlag && <div className="d-flex justify-content-end pt-2 text-right">
                                        <Button
                                            className="btn btn-md mx-3"
                                            disabled
                                        >
                                            Processing . . .
                                        </Button>

                                        <Button
                                            className="btn btn-lg btn-border-light"
                                            variant="light"
                                            disabled
                                        >
                                            No
                                        </Button>
                                    </div>}
                                </div>
                            </Container>
                        </div>
                    )}
                </Popup>

                <div className='service-management-acronis-header mt-5'>
                    <Row>
                        <Col className="mx-md-3 col-sm-12 col-xs-12">

                        </Col>
                    </Row>

                    <Row>
                        <Col md={3}>
                            <SearchDropdown
                                dropdownLabel="Cloud Service"
                                formGroupId="serviceNameId"
                                placeholder="Enter Service Name"
                                selectDefaultValue={serviceNameDefaultValue}
                                setSelectDefaultValue={setServiceNameDefaultValue}
                                selectOptions={serviceData}
                                selectOptionsFiltered={serviceNameFiltered}
                                setSelectOptionsFiltered={setServiceNameFiltered}
                                selectSearchValue={serviceNameSearchValue}
                                setSelectSearchValue={setServiceNameSearchValue}
                                updatedOptions={updatedServiceName}
                                setOptionId={setServiceId}
                                setInitialSubscriptionTableCall={setIsNotProvisioned}
                            />
                        </Col>

                        <Col md={3}>
                            <SearchDropdown
                                dropdownLabel="Customer Name"
                                formGroupId="customerNameId"
                                placeholder="Enter Customer Name"
                                selectDefaultValue={customerNameDefaultValue}
                                setSelectDefaultValue={setCustomerNameDefaultValue}
                                setOptionId={setCustomerId}
                                selectOptions={customerData}
                                selectOptionsFiltered={customerNameFiltered}
                                setSelectOptionsFiltered={setCustomerNameFiltered}
                                selectSearchValue={customerNameSearchValue}
                                setSelectSearchValue={setCustomerNameSearchValue}
                                updatedOptions={updatedCustomerName}
                                setInitialSubscriptionTableCall={setIsNotProvisioned}
                            />
                        </Col>

                        <Col md={6} className='d-none d-md-block text-right'>
                            <br />
                            {!isNotProvisioned && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="light" onClick={() => setModalOpen(true)}>Cloud Portal Details</Button>}
                            {isNotProvisioned && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="secondary" disabled >Cloud Portal Details</Button>}
                        </Col>

                        <Col md={6} className='d-blok d-md-none text-right mt-4'>
                            <div className='d-flex justify-content-between'>
                                {!isNotProvisioned && <Button className="btn-border-light w-md-auto col-xs-12" variant="light" onClick={() => setModalOpen(true)}>Cloud Portal Details</Button>}
                                {isNotProvisioned && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="secondary" disabled >Cloud Portal Details</Button>}
                                <Button className="mb-4" onClick={(e) => { DownloadAction(ACRONIS_SERVICE_MANAGEMENT_PRICE_LIST, "Acronis Data Cloud Service Provider (SP) offering.pdf", "pdf") }}>Download Price List</Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="service-management-acronis-body">
                    <Row className='align-items-center mt-3'>
                        <Col lg={3} md={4}>
                            <h2>Monthly Commitment Plan Details</h2>
                        </Col>
                        {!isNotProvisioned && !isServerError && nepheleStatus === "ACTIVE" && <Col md={6} className="d-none d-md-block">
                            <h2>Upgrades Available</h2>
                        </Col>}
                        <Col className='d-md-block d-none'>
                            <Button className="ml-3 mb-4 float-right" onClick={(e) => { DownloadAction(ACRONIS_SERVICE_MANAGEMENT_PRICE_LIST, "Acronis Data Cloud Service Provider (SP) offering.pdf", "pdf") }}>Download Price List</Button>
                        </Col>
                    </Row>
                    {!isNotProvisioned && !isServerError && nepheleStatus === "ACTIVE" && <div className='service-management-acronis-body-sub'>
                        <Row>
                            <Col lg={3} md={4} className='mb-3'>
                                <div className='white-box text-muted position-relative'>
                                    <h4>Current Value</h4>
                                    {(commitmentAmount >= 0) && <p>${commitmentAmount}</p>}
                                    {isTrial ? (
                                        <Button className={isreadOnly ? 'd-none' : ' pl-5 pr-5 position-absolute bottom-0 end-0 mb-3 mr-5 d-none-mobile'} onClick={() => setConvertPlanFlag(true)}>Convert To Paid</Button>
                                    ) : null}
                                </div>
                            </Col>

                            <Col lg={9} md={8} className='mb-3'>

                                {!isNotProvisioned && !isServerError && nepheleStatus === "ACTIVE" && <Col md={6} className="d-block d-md-none">
                                    <h2>Upgrades Available</h2>
                                </Col>}
                                {upgradeValuesData && !isServerError && upgradeValuesData.length > 0 && <div className='white-box text-muted'>
                                    <h4>{isTrial ? "Select to Upgrade Trial" : "Select to Upgrade"}</h4>
                                    {nepheleStatus === "ACTIVE" && <div className='text-muted'>
                                        {
                                            upgradeValuesData &&
                                            <Form onChange={(e) => {
                                                var myCheckbox = document.getElementsByName("myCheckbox");
                                                Array.prototype.forEach.call(myCheckbox, function (el) {
                                                    el.checked = false;
                                                });
                                                e.target.checked = true;
                                                setUpgradeUpdatedValues(e.target.value);
                                            }}>
                                                {
                                                    upgradeValuesData.map((value) => {
                                                        return (<Form.Check value={value.id} name='myCheckbox' disabled={isreadOnly} type="radio" aria-label="radio 1" label={"$" + value.value} />)
                                                    })
                                                }
                                            </Form>
                                        }
                                    </div>}
                                    {(nepheleStatus === "ACTIVE" && !isreadOnly) &&
                                        <div className='text-right my-2 mb-0'>
                                            {upgradeUpdatedValues && <Button
                                                className="btn-border-light w-md-auto col-xs-12"
                                                variant="light"
                                                onClick={() => CancelUpgradeSubscription()}
                                            >
                                                Clear
                                            </Button>}
                                            {!upgradeUpdatedValues && <Button
                                                className="btn-border-light w-md-auto col-xs-12"
                                                variant="light"
                                                disabled
                                            >
                                                Clear
                                            </Button>}

                                            {upgradeUpdatedValues && <Button
                                                className="ml-3 w-md-auto col-xs-12"
                                                onClick={() => setUpgradePlanFlag(true)}
                                            >
                                                Proceed
                                            </Button>}
                                            {!upgradeUpdatedValues &&
                                                <Button
                                                    className="ml-3 w-md-auto col-xs-12"
                                                    variant="disabled"
                                                    disabled
                                                >
                                                    Proceed
                                                </Button>
                                            }
                                        </div>}
                                </div>}
                                {
                                    upgradeValuesData && upgradeValuesData.length === 0 &&
                                    <div className='white-box text-muted'>
                                        <h4>No Upgrades Available</h4>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>}
                    {nepheleStatus === "INACTIVE" &&
                        <div className="no-data-user mb-10">
                            <img src={InactiveServiceSubscription} className="empty-customers-table-svg" />
                            <h2 className="mt-4 mb-3">Cloud Service is Inactive</h2>
                            <p className="mb-3">Please raise ticket to activate the cloud service.</p>
                            <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                toggleDrawer(true)();
                                setSelectService(defaultServiceName);
                                setSelectCategory(defaultCategoryName)
                            }}>Raise Ticket</Button>
                        </div>
                    }
                    {isNotProvisioned &&
                        <div className="no-data-user mb-10">
                            <img src={EmptyServiceProvision} className="empty-customers-table-svg" />
                            <h2 className="mt-4 mb-3">Selected Cloud Service is not provisioned</h2>
                            <p className="mb-3">No Subscriptions are currently available for this cloud service.</p>
                            {(resellerId?.length == 0) && <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                navigate("/cloud-provision", {
                                    state: { customerId: customerId, serviceId: serviceId }
                                })
                            }}>Provision Cloud Service</Button>}
                        </div>
                    }
                    {isServerError &&
                        <div className="no-data-user mb-10">
                            <img src={EmptyDataTableFilterSearch} className="empty-user-management-table-svg" />
                            <h2 className="mt-4 mb-3">Facing issues in reaching server, Try again Later !</h2>
                        </div>
                    }
                    {nepheleStatus === "ACTIVE" && <div className='mt-5'>
                        <p>
                            <b>Note:</b> If you require support for following:
                            <ul className='service-management-rules'>
                                <li>Downgrade minimum monthly commitment plan to lower then your current plan.</li>
                                <li>Require further details on upcoming products, plans and pricing.</li>
                                <li>Managing Tenants and services under your Acronis Cyber Cloud account.</li>
                                <li>Delete Acronis Cyber Cloud account and stop monthly billing.</li>
                                <li> Any other general enquiry regarding Acronis.</li>
                            </ul>
                            <br />
                            Please raise a <a className='text-strong cpointer' onClick={() => {
                                toggleDrawer(true)();
                                setSelectService(defaultServiceName);
                                setSelectCategory(defaultCategoryName)
                            }}>Support ticket</a>
                        </p>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default AcronisServiceManagement