import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { createTheme } from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import EmptyDataTableFilterSearch from '../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import RaiseTicketIllustration from '../../../Assets/Images/Illustrations/raiseticketillustration.svg';
import AccessDenied from '../../../GlobalComponents/EmptyStates/AccessDenied';
import { api } from "../../../Utils/API";
import { GET_CUSTOMERS_LIST, GET_ORDER_STATUS_LIST, ORDER_HISTORY_ADDITIONAL_DETAILS, ORDER_HISTORY_LISTING_TABLE, PROVISIONING_GET_SERVICES, RESELLER_API } from '../../../Utils/GlobalConstants';
import OrderHistoryFilterBox from './LocalComponents/FilterBox';
import LazyTable from './LocalComponents/LazyTable';
import styles from './orderHistory.module.scss';
import ResellerNavdropdown from '../../../GlobalComponents/ResellerNavdropdown';

const OrderHistory = (props) => {

    const navigate = useNavigate();                                                      // to use navigate function from react router dom    
    const date = new Date();                                                             // for js date time function
    const [searchIconFlag, setSearchIconFlag] = useState(false);                         // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(null);                      // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(false);                                 // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                           // showing filter box
    const searchInputRef = useRef();                                                     // Search Input Box
    const [isDisabled, setIsDisabled] = useState(localStorage.getItem("disabled_array")  // Order History disabled role management
     && localStorage.getItem("disabled_array").includes("Order History") ? true : false);

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                             // table active page number
    const [pageSize, setPageSize] = useState(15);                                        // table active page size
    const [totalRows, setTotalRows] = useState(0);                                       // table total count of data from api 
    const [columns, setColumns] = useState([]);                                          // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                              // data state used to display data in react data table component
    let ColumnName = ["Order ID", "Service",                                             // table headers used to make object of react data table component headers
        "Order Date", "Customer Name", "Order By",
        "Provision Tag", "Status"];
    const [initialLoading, setInitialLoading] = useState(true);                          // loader for table

    const [serverErrorOrderHistory, setServerErrorOrderHistory] = useState(false);       // server error check state in table during api call to load data
    const [emptyOrderHistory, setEmptyOrderHistory] = useState(false);                   // empty table check state in table during api call to load data
    const [emptyOrderHistoryFilters, setEmptyOrderHistoryFilters] = useState(false);     // empty filter response check state in table during api call to load data
    const [emptyOrderHistorySearch, setEmptyOrderHistorySearch] = useState(false);       // empty search response check state in table during api call to load data

    const [isDescOrderId, setIsDescOrderId] = useState(true);                            // sort state check for order History id column
    const [isDescOrderDate, setIsDescOrderDate] = useState(null);                        // sort state check for order History date column
    const [isDescCloudService, setIsDescCloudService] = useState(null);                  // sort state check for cloud service column
    const [isDescOrderBy, setIsDescOrderBy] = useState(null);                            // sort state check for orderBy column
    const [isDescCustomer, setIsDescCustomer] = useState(null);                          // sort state check for cloud Customer column
    const [isDescStatus, setIsDescStatus] = useState(null);                              // sort state check for Status column

    // Get Table Data Function Parameters
    const [serviceId, setServiceId] = useState(null);                                   // getTableData() service id
    const [orderHistoryDateFrom, setOrderHistoryDateFrom] = useState(null);             // getTableData() order history from date
    const [orderHistoryDateTo, setOrderHistoryDateTo] = useState(null);                 // getTableData() order history to date
    const [sortCol, setSortCol] = useState("orderCode");                                // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("DESC");                                     // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending
    const [customerId, setCustomerId] = useState(null);                                 // getTableData() customer id
    const [orderStatus, setOrderStatus] = useState(null);                               // getTableData() Status 
    const [orderType, setOrderType] = useState(null);                                   // data in order type dropdown in filter box
    const [serviceData, setServiceData] = useState(null);                               // data in service dropdown
    const [statusData, setStatusData] = useState(null);                                 // data in status dropdown
    const [customerData, setCustomerData] = useState(null);                             // data in customer dropdown

    const [orderHistoryServiceLastUsed, setOrderHistoryServiceLastUsed]
        = useState("default");                                                          // service dropdown saved data after applying filters
    const [orderHistoryDateLastUsedDate, setOrderHistoryDateLastUsedDate]
        = useState("default");                                                          // order History date dropdown saved data after applying filters
    const [customerLastUsed, setCustomerLastUsed]
        = useState("default");                                                          // customer dropdown saved data after applying filters
    const [statusLastUsed, setStatusLastUsed]
        = useState("default");                                                          // status dropdown saved data after applying filters  
    const [orderHistoryOrderTypeLastUsed, setOrderHistoryOrderTypeLastUsed]
        = useState("default");                                                          // Order Type dropdown saved data after applying filters  
    const [modalOpen, setModalOpen] = useState(false);                                  // details popup toggle state
    const [additionalDetails, setAdditionalDetails] = useState();                       // popup for details in cloud order id
    const [infoLoadingPopup, setInfoLoadingPopup] = useState(false);                    // loading state while popup is not loaded

    // if custom was selected in order summary date in filter box
    const [orderHistoryDateCustomFromLast, setOrderHistoryDateCustomFromLast]
        = useState(date.toISOString().slice(0, 10));                                    // order History from date saved data after applying filter having custom selection
    const [orderHistoryDateCustomToLast, setOrderHistoryDateCustomToLast]
        = useState(date.toISOString().slice(0, 10));                                    // order History to date saved data after applying filter having custom selection

    //lazy loading
    const [scrollToDivTop, setScrollToDivTop] = useState(false);                        // usestate to scroll to top when applied filter or search when already scrolled
    const [infinityLoading, setInfinityLoading] = useState(false);                      // loading state while table loads for lazy loading

    const [autoRefreshInterval, setAutoRefreshInterval] = useState(10000); // 10 seconds default
    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(true);
    const [isFilterOrSearchActive, setIsFilterOrSearchActive] = useState(false);
    const [isSorting, setIsSorting] = useState(false);
    const [hasInProgressStatus, setHasInProgressStatus] = useState(false);
    const [pagesWithInProgress, setPagesWithInProgress] = useState(new Set());
    const [loadedPages, setLoadedPages] = useState(new Set([1]));
    const [isSearchActive, setIsSearchActive] = useState(false);

    // reseller dropdown for  staff users
    const [resellerData, setResellerData] = useState([]);                            // data in customer dropdown
    const [resellerNameDefaultValue, setResellerNameDefaultValue] = useState("Resellers");
    const [resellerNameSearchValue, setResellerNameSearchValue] = useState();
    const [resellerName, setResellerName] = useState([]);
    const [resellerNameFiltered, setResellerNameFiltered] = useState(resellerName);
    const updatedResellerName = [];
    const [resellerId, setResellerId] = useState("");
    const [isCloudAdmin, setIsCloudAdmin] = useState(localStorage.getItem("curentRole") && localStorage.getItem("curentRole").toLowerCase().includes("cloud admin view") ? true : false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // // Function to call all dropdown values in Reseller Section in Filter Box
    async function GetAllResellers() {
      await api.get(RESELLER_API, config)
          .then(resp => {
              setResellerData(resp.data);
          })
          .catch(error => {
              if (error?.status == 401 || error?.response.status == 401) {
                  props.Logout();
              }
              else {
              }
          });
  };


    // Get Table Data with Filters, Pagination, Search and Sort Functionality
    const GetTableData = async (page, newPerPage, search, serviceId, orderHistoryDateFrom, orderHistoryDateTo, sortCol, sortDir, customerId, orderStatus, orderType, appendData, isFilter) => {
    setInitialLoading(false);
    resetEmptyStates();
    try {
      const response = await api.get(`${ORDER_HISTORY_LISTING_TABLE}?pageNo=${page}&pageSize=${newPerPage}&orderCode=${search || ""}&customerId=${customerId || ""}&status=${orderStatus || ""}&serviceId=${serviceId || ""}&orderType=${orderType || ""}&fromDate=${orderHistoryDateFrom || ""}&toDate=${orderHistoryDateTo || ""}&sortCol=${sortCol}&sortDir=${sortDir}&requestedResellerCode=${resellerId}`, config);
      
      setInitialLoading(true);
      setInfinityLoading(false);

      if (response.status === 204) {
        handleEmptyResponse(search, serviceId, orderHistoryDateFrom, orderHistoryDateTo, customerId, orderStatus, isFilter);
      } else {
        const newData = processResponseData(response.data.content);
        const hasInProgress = response.data.content.some(item => item.orderStatus === 'IN PROGRESS' || item.orderStatus === 'PENDING');
        updateInProgressPages(page, hasInProgress);
        setHasInProgressStatus(hasInProgress);
        setLoadedPages(prev => new Set(prev).add(page));
        setIsAutoRefreshEnabled(true);
        setIsSorting(false);
        setTotalRows(response.data.totalCount);

        if (appendData) {
          setTable(prevTable => {
            const updatedTable = prevTable.map(item => {
                        const updatedItem = newData.find(newItem => newItem['Order ID'].props.children === item['Order ID'].props.children);
                        return updatedItem || item;
                    });
            const newItems = newData.filter(item => !prevTable.some(prevItem => prevItem['Order ID'].props.children === item['Order ID'].props.children));
            return [...updatedTable, ...newItems];
          });
        } else {
          setTable(newData);
        }

        updateColumns(response.data.content);
      }
    } catch (error) {
      handleApiError(error);
    }
};
    const resetEmptyStates = () => {
      setEmptyOrderHistory(false);
      setEmptyOrderHistoryFilters(false);
      setEmptyOrderHistorySearch(false);
      setServerErrorOrderHistory(false);
    };

    const processResponseData = (content) => {
     return content.map(td => ({
      "Order ID": <span className={styles["order-history-color-underline"]} onClick={() => { GetAdditionalDetails(td.orderId) }}>{td.orderCode}</span>,
      "Order Date": td.orderDate,
      "Service": td.serviceName,
      "Customer Name": <span title={td.customerName}>{td.customerName}</span>,
      "Order By": td.orderBy,
      "Provision Tag": td.purchaseOrderNumber,
      "Status": getStatusComponent(td.orderStatus),
     }));
    };

    const getStatusComponent = (status) => {
    switch (status) {
    case 'FAILED':
      return (
        <div className={`${styles['status-orderhistory']}`}>
          <span className="status-failed"></span>
          <div className="py-1 text-muted text-small">Failed</div>
        </div>
      );
    case 'COMPLETED':
      return (
        <div className={`${styles['status-orderhistory']}`}>
          <span className="status-paid"></span>
          <div className="py-1 text-muted text-small">Completed</div>
        </div>
      );
    case 'APPROVED':
      return (
        <div className={`${styles['status-orderhistory']}`}>
          <span className="status-paid"></span>
          <div className="py-1 text-muted text-small">Approved</div>
        </div>
      );
    default:
      return (
        <div className={`${styles['status-orderhistory']}`}>
          <span className="status-inprogress"></span>
          <div className="py-1 text-muted text-small">In-Progress</div>
        </div>
      );
    }
    };

    const handleEmptyResponse = (search, serviceId, orderHistoryDateFrom, orderHistoryDateTo, customerId, orderStatus, isFilter) => {
      console.log("Dataaaa: ",search,serviceId,orderHistoryDateFrom,orderHistoryDateTo,customerId,orderStatus,isFilter)
    if (search === "" && serviceId === "" && orderHistoryDateFrom === "" && orderHistoryDateTo === "" && customerId === "" && orderStatus === "") {
    setEmptyOrderHistory(true);
    console.log("Dataaaa 1111 ");
    } else if ((serviceId !== "" || orderHistoryDateFrom !== "" || orderHistoryDateTo !== "" || customerId !== "" || orderStatus !== "") && isFilter) {
    setEmptyOrderHistoryFilters(true);
    console.log("Dataaaa 2222 ");
    } else if (search !== "" && !isFilter) {
    setEmptyOrderHistorySearch(true);
    console.log("Dataaaa 3333 ");
    }
    else{
      setEmptyOrderHistory(true);
    }
    };

    const handleApiError = (error) => {
    if (error?.status === 401 || error?.response?.status === 401) {
    props.Logout();
    } else {
    setServerErrorOrderHistory(true);
    console.error("API Error:", error);
    }
    }; 

    const updateColumns = () => {
    let d = [];
    ColumnName.forEach((td) => {
    let column = {
      id: td,
      name: <div>{td}</div>,
      selector: row => <div className="text-wrap">{row[td]}</div>,
      left: true,
    };

    switch(td) {
      case "Order ID":
        column.grow = 3;
        column.name = (
          <div>
            {td}
            {isDescOrderId === null && 
              <span className="sort-icon-disabled sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescOrderId(true); 
                  setSortCol("orderCode"); 
                  setIsDescOrderDate(null); 
                  setIsDescCloudService(null); 
                  setIsDescOrderBy(null); 
                  setIsDescCustomer(null); 
                  setIsDescStatus(null); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescOrderId === true && 
              <span className="sort-icon-asc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("ASC"); 
                  setIsDescOrderId(false); 
                  setSortCol("orderCode"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescOrderId === false && 
              <span className="sort-icon-desc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescOrderId(true); 
                  setSortCol("orderCode"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
          </div>
        );
        break;

      case "Order Date":
        column.name = (
          <div>
            {td}
            {isDescOrderDate === null && 
              <span className="sort-icon-disabled sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescOrderDate(true); 
                  setSortCol("sortingDate"); 
                  setIsDescOrderId(null); 
                  setIsDescCloudService(null); 
                  setIsDescOrderBy(null); 
                  setIsDescCustomer(null); 
                  setIsDescStatus(null); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescOrderDate === true && 
              <span className="sort-icon-asc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("ASC"); 
                  setIsDescOrderDate(false); 
                  setSortCol("sortingDate"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescOrderDate === false && 
              <span className="sort-icon-desc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescOrderDate(true); 
                  setSortCol("sortingDate"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
          </div>
        );
        break;

      case "Service":
        column.grow = 1.5;
        column.name = (
          <div>
            {td}
            {isDescCloudService === null && 
              <span className="sort-icon-disabled sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescCloudService(true); 
                  setSortCol("serviceName"); 
                  setIsDescOrderDate(null); 
                  setIsDescOrderId(null); 
                  setIsDescOrderBy(null); 
                  setIsDescCustomer(null); 
                  setIsDescStatus(null); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescCloudService === true && 
              <span className="sort-icon-asc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("ASC"); 
                  setIsDescCloudService(false); 
                  setSortCol("serviceName"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescCloudService === false && 
              <span className="sort-icon-desc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescCloudService(true); 
                  setSortCol("serviceName"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
          </div>
        );
        break;

      case "Order By":
        column.grow = 1;
        column.centre = true;
        column.name = (
          <div>
            {td}
            {isDescOrderBy === null && 
              <span className="sort-icon-disabled sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescOrderBy(true); 
                  setSortCol("orderBy"); 
                  setIsDescOrderDate(null); 
                  setIsDescOrderId(null); 
                  setIsDescCloudService(null); 
                  setIsDescCustomer(null); 
                  setIsDescStatus(null); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescOrderBy === true && 
              <span className="sort-icon-asc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("ASC"); 
                  setIsDescOrderBy(false); 
                  setSortCol("orderBy"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescOrderBy === false && 
              <span className="sort-icon-desc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescOrderBy(true); 
                  setSortCol("orderBy"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
          </div>
        );
        break;

      case "Customer Name":
        column.grow = 2;
        column.name = (
          <div>
            {td}
            {isDescCustomer === null && 
              <span className="sort-icon-disabled sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescCustomer(true); 
                  setSortCol("customerName"); 
                  setIsDescOrderDate(null); 
                  setIsDescOrderId(null); 
                  setIsDescCloudService(null); 
                  setIsDescOrderBy(null); 
                  setIsDescStatus(null); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescCustomer === true && 
              <span className="sort-icon-asc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("ASC"); 
                  setIsDescCustomer(false); 
                  setSortCol("customerName"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescCustomer === false && 
              <span className="sort-icon-desc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescCustomer(true); 
                  setSortCol("customerName"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
          </div>
        );
        break;

      case "Status":
        column.centre = true;
        column.name = (
          <div>
            {td}
            {isDescStatus === null && 
              <span className="sort-icon-disabled sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescStatus(true); 
                  setSortCol("orderStatus"); 
                  setIsDescOrderDate(null); 
                  setIsDescOrderId(null); 
                  setIsDescCloudService(null); 
                  setIsDescCustomer(null); 
                  setIsDescOrderBy(null); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescStatus === true && 
              <span className="sort-icon-asc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("ASC"); 
                  setIsDescStatus(false); 
                  setSortCol("orderStatus"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
            {isDescStatus === false && 
              <span className="sort-icon-desc sort-icon-sub-position mx-1" 
                onClick={() => { 
                  setScrollToDivTop(true); 
                  setSortDir("DESC"); 
                  setIsDescStatus(true); 
                  setSortCol("orderStatus"); 
                  setIsSorting(true); 
                  setPagesWithInProgress(new Set());
                  setIsAutoRefreshEnabled(false);
                }}
              ></span>
            }
          </div>
        );
        break;

      default:
        column.grow = 1.5;
        break;
    }
    d.push(column);
    });
    setColumns(d);
    };

    function updateInProgressPages(page, hasInProgress) {
    setPagesWithInProgress(prev => {
        const newSet = new Set(prev);
        if (hasInProgress) {
            newSet.add(page);
        } else {
            newSet.delete(page);
        }
        return newSet;
    });
    }

    const refreshData = () => {
        for (let page of pagesWithInProgress) {
        if (loadedPages.has(page)) {
            GetTableData(page, pageSize, searchValueTable, serviceId, orderHistoryDateFrom, orderHistoryDateTo, sortCol, sortDir, customerId, orderStatus, orderType, true);
        }
        }
    };

    // function to call all additional details of order history (popup data)
    async function GetAdditionalDetails(id) {
        setInfoLoadingPopup(true);
        setModalOpen(true);
        await api.get(`${ORDER_HISTORY_ADDITIONAL_DETAILS + id}?requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                // console.log("Data : ", resp.data);
                setAdditionalDetails(resp.data);
                setInfoLoadingPopup(false);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                    // console.log(error);
                    setInfoLoadingPopup(false);
                }

            });
    };

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetAllServices() {
        await api.get(PROVISIONING_GET_SERVICES, config)
            .then(resp => {
                setServiceData([{ id: "0", name: 'All Services', integrationCode: 'default' }, ...resp.data]);
            })
            .catch(error => {              
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(GET_ORDER_STATUS_LIST, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    // console.log("Error 401");
                    props.Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(`${GET_CUSTOMERS_LIST}?requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setCustomerData(resp.data);
            })
            .catch(error => {
                // console.log(error)
                if (error?.status == 401 || error?.response?.status == 401) {
                    props.Logout();
                }
                else {
                }

            });
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
        setInfinityLoading(true);
        setPageNo((prev) => {
            const newPageNumber = prev + 1;
            (async () => await GetTableData(newPageNumber, pageSize, searchValueTable, serviceId, orderHistoryDateFrom, orderHistoryDateTo, sortCol, sortDir, customerId, orderStatus, orderType, true))();
            return newPageNumber;
        });
    }

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
    if (searchValueTable !== undefined && searchValueTable !== null) {
        const getData = setTimeout(() => {
            if (searchValueTable.length > 0) {
                setEmptyOrderHistory(false);
                setEmptyOrderHistorySearch(false);
                setEmptyOrderHistoryFilters(false);
                setScrollToDivTop(true);
                setPageNo(1);
                setIsSearchActive(true);
                GetTableData(1, pageSize, searchValueTable, serviceId, orderHistoryDateFrom, orderHistoryDateTo, sortCol, sortDir, customerId, orderStatus, orderType);
                setIsAutoRefreshEnabled(false);
                setPagesWithInProgress(new Set());
            } else if (isSearchActive) {
                setEmptyOrderHistory(false);
                setEmptyOrderHistorySearch(false);
                setEmptyOrderHistoryFilters(false);
                setIsSearchActive(false);
                setScrollToDivTop(true);
                setPageNo(1);
                GetTableData(1, pageSize, searchValueTable, serviceId, orderHistoryDateFrom, orderHistoryDateTo, sortCol, sortDir, customerId, orderStatus, orderType);
                setIsAutoRefreshEnabled(true);
                setPagesWithInProgress(new Set());
            }
        }, 800);
        return () => clearTimeout(getData);
    }
    }, [searchValueTable]);


    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '400px'
            },
        },
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'left',  
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                // wordBreak:'normal',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                textAlign: 'center',
            },
        },
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, serviceId, orderHistoryDateFrom, orderHistoryDateTo, sortCol, sortDir, customerId, orderStatus, orderType);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, pageNo) => {
        setPageNo(pageNo);
        setPageSize(newPerPage);
        GetTableData(pageNo, newPerPage, searchValueTable, serviceId, orderHistoryDateFrom, orderHistoryDateTo, sortCol, sortDir, customerId, orderStatus, orderType);
    };

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        setPageNo(1);
        GetTableData(1, pageSize, searchValueTable, serviceId, orderHistoryDateFrom, orderHistoryDateTo, sortCol, sortDir, customerId, orderStatus, orderType);
    }, [isDescOrderId, isDescOrderDate, isDescCloudService, isDescOrderBy, isDescCustomer, isDescStatus])


    useEffect(() => {
        localStorage.getItem("navigation_link") ?  localStorage.removeItem("navigation_link") : localStorage.setItem("navigation_link","/synnex-cloud/order-history");
        GetAllServices();
        GetAllStatuses();
        GetAllCustomers();
        { isCloudAdmin && GetAllResellers(); }
    }, []);

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    useEffect(() => {
    const filterActive = serviceId || orderHistoryDateFrom || orderHistoryDateTo || customerId || orderStatus || orderType;
    const searchActive = searchValueTable && searchValueTable.length > 0;
    setIsFilterOrSearchActive(filterActive || searchActive);
    }, [serviceId, orderHistoryDateFrom, orderHistoryDateTo, customerId, orderStatus, orderType, searchValueTable]);

    const handleClearFilter = () => {
    setServiceId(null);
    setOrderHistoryDateFrom(null);
    setOrderHistoryDateTo(null);
    setOrderStatus(null);
    setCustomerId(null);
    setOrderType(null);
    setFilterFlag(false);
    setShowFilterBox(false);
    setPageNo(1);
    setPagesWithInProgress(new Set());
    setIsAutoRefreshEnabled(true);
  
    // Reset all filter-related states to their default values
    setOrderHistoryServiceLastUsed("default");
    setOrderHistoryDateLastUsedDate("default");
    setOrderHistoryOrderTypeLastUsed("default");
    setCustomerLastUsed("default");
    setStatusLastUsed("default");
    setEmptyOrderHistory(false);
    setEmptyOrderHistorySearch(false);
    setEmptyOrderHistoryFilters(false);
    };

    useEffect(() => {
    if (isAutoRefreshEnabled && !isFilterOrSearchActive && !isSorting && pagesWithInProgress.size > 0) {
        const intervalId = setInterval(() => {
            refreshData();
        }, autoRefreshInterval);
        if(resellerId!=="" && pageNo==1){
          clearInterval(intervalId)
        }
        return () => clearInterval(intervalId);
    }
    }, [isAutoRefreshEnabled, autoRefreshInterval, isFilterOrSearchActive, isSorting, pagesWithInProgress,resellerId]);

    useEffect(() => {
    if (showFilterBox) {
        setIsAutoRefreshEnabled(false);
    }
    }, [showFilterBox]);

        // useEffct to select reseller for staff user
        useEffect(() => {
          setTable([]);
          setIsAutoRefreshEnabled(false);
          setScrollToDivTop(true);
          setServiceId(null);
          setOrderHistoryDateFrom(null);
          setOrderHistoryDateTo(null);
          setOrderStatus(null);
          setCustomerId(null);
          setOrderType(null);
          setFilterFlag(false);
          setShowFilterBox(false);
          setPageNo(1);
          setSearchValueTable(null);
          setSearchIconFlag(false);
          // setPagesWithInProgress(new Set());
          // setIsAutoRefreshEnabled(true);
        
          // Reset all filter-related states to their default values
          setOrderHistoryServiceLastUsed("default");
          setOrderHistoryDateLastUsedDate("default");
          setOrderHistoryOrderTypeLastUsed("default");
          setCustomerLastUsed("default");
          setStatusLastUsed("default");
          setEmptyOrderHistory(false);
          setEmptyOrderHistorySearch(false);
          setEmptyOrderHistoryFilters(false);
        
          // if (summaryDateLastUsedDate !== "default") {
          //     setSummaryDateLastUsedDate("default");
          //     const summaryElement = document.getElementById(summaryDateLastUsedDate);
          //     if (summaryElement) {
          //         summaryElement.checked = false; // Uncheck the checkbox if it exists
          //     }
          // }
          
          // if (dueDateLastUsedDate !== "default") {
          //     setDueDateLastUsedDate("default");
          //     const dueElement = document.getElementById("due_date_" + dueDateLastUsedDate);
          //     if (dueElement) {
          //         dueElement.checked = false; // Uncheck the checkbox if it exists
          //     }
          // }
          // setSummaryDateCustomFromLast(date.toISOString().slice(0, 10));
          // setDueDateCustomFromLast(date.toISOString().slice(0, 10));
          // setDueDateCustomToLast(date.toISOString().slice(0, 10));
          // setSummaryDateCustomToLast(date.toISOString().slice(0, 10));
          // setFilterFlag(false);
          GetTableData(1, pageSize, null, null, null, null, sortCol, sortDir, null, null, null);
          GetAllCustomers();
      }, [resellerId]);

    return (
        <>
            <div className="main-content">
                <div className="customer-management-console-main-div mb-5">
                {isCloudAdmin && <Container fluid><Col md={3}>
                                <ResellerNavdropdown
                                    formGroupId="customerNameId"
                                    placeholder="Enter Reseller Code"
                                    selectDefaultValue={resellerNameDefaultValue}
                                    setSelectDefaultValue={setResellerNameDefaultValue}
                                    setOptionId={setResellerId}
                                    selectOptions={resellerData}
                                    selectOptionsFiltered={resellerNameFiltered}
                                    setSelectOptionsFiltered={setResellerNameFiltered}
                                    selectSearchValue={resellerNameSearchValue}
                                    setSelectSearchValue={setResellerNameSearchValue}
                                    updatedOptions={updatedResellerName}
                                />
                            </Col></Container>}
                    <Popup
                        open={modalOpen}
                        onClose={() => { setModalOpen(false); }}
                        className="custom-modal custom-modal-md custom-modal-ticket-details"
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header">
                                    <h2>Order Details</h2>
                                    <XLg
                                        size={24}
                                        className="cpointer close-icon-popup"
                                        onClick={close}
                                    />
                                </div>
                                <Container>
                                    {
                                        infoLoadingPopup &&
                                        <div className={`${styles['ticket-table-additional-information-popup-loading']}`}>
                                            <p>Loading . . .</p>
                                        </div>
                                    }
                                    {!infoLoadingPopup && (additionalDetails) && <Row className={`${styles['ticket-table-additional-information-popup-loading-row']}`}>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Order ID</label>
                                            <p><strong>{additionalDetails.orderCode}</strong> <span className={`${styles['tags-product-listing-trial']} cpointer`} onClick={() => { navigate('/management-console', { state: { resellerId: resellerId,searchData: additionalDetails.customerCompanyName.split(" ")[0] } }) }}>Service Details</span></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Customer Name</label>
                                            <p><strong>{additionalDetails.customerCompanyName}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Order By</label>
                                            <p><strong>{additionalDetails.orderBy}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">PO Number</label>
                                            <p><strong>{additionalDetails.purchaseOrderNumber}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Order Date</label>
                                            <p><strong>{additionalDetails.orderDate}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Status</label>
                                            <p><strong>{additionalDetails.status}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Product</label>
                                            <p><strong>{additionalDetails.productName}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-4">
                                            <label className="text-medium">Cloud Service</label>
                                            <p><strong>{additionalDetails.serviceName}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Plan</label>
                                            <p><strong>{additionalDetails.planName}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">SKU</label>
                                            <p><strong>{additionalDetails.sku}</strong></p>
                                        </Col>
                                        <Col md={12} className="mb-2">
                                            <label className="text-medium">Message</label>
                                            <p className={`${styles['order-history-message']}`}><strong>{additionalDetails.statusMessage}</strong></p>
                                        </Col>
                                    </Row>}
                                </Container>
                            </div>
                        )}
                    </Popup>

                    {!isDisabled && <div className="mgmt-console-table-row">
                        <div className="mb-3 d-flex">
                            <Col className={`py-0 overflow-auto ${styles['order-history-table']}`}>
                                <div className={`${styles['order-history-table-inner']} ${styles['table-details']} details-box`}>
                                    <div className="my-0 table-heading align-items-center row">
                                        <Col className={`pl-1 ${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                            <h5 className="py-1 px-2 mb-0 mobile-padding-search-open subscription-heading-table-header">Order History {!emptyOrderHistory && !emptyOrderHistoryFilters && !emptyOrderHistorySearch && initialLoading && <span className='text-muted text-large'>({totalRows})</span>}</h5>
                                        </Col>
                                        {/* desktop screen search */}
                                        <Col className="px-1 d-none d-md-block">
                                            <Row className="justify-content-end align-items-center mobile-padding-search-open-sub pr-4">
                                                {!serverErrorOrderHistory && !emptyOrderHistory && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                    {!searchIconFlag &&
                                                        <span className="mx-3">&nbsp;</span>
                                                    }
                                                    {!searchIconFlag && !emptyOrderHistoryFilters && !emptyOrderHistorySearch &&
                                                        <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                    }
                                                    {searchIconFlag && !emptyOrderHistoryFilters &&
                                                        <InputGroup className="search-input-box search-input-box-large">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search by Cloud Order ID"
                                                                aria-label="Search by Cloud Order ID"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => {
                                                                setSearchValueTable(e.target.value);
                                                                if (e.target.value && e.target.value.length > 0) {
                                                                setIsAutoRefreshEnabled(false);
                                                                }else{
                                                                setIsAutoRefreshEnabled(true);
                                                                }
                                                                }}                                                              
                                                                ref={searchInputRef}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    }

                                                    {!filterFlag && !emptyOrderHistorySearch && <span 
                                                        className="filter-black cpointer" 
                                                        id="filterblock" 
                                                        onClick={() => {
                                                        setShowFilterBox(!showFilterBox);
                                                        if (!showFilterBox) {
                                                        setIsAutoRefreshEnabled(false);
                                                        } else {
                                                        // Only re-enable auto-refresh if no filters are active
                                                        if (!isFilterOrSearchActive) {
                                                        setIsAutoRefreshEnabled(true);
                                                        }
                                                        }
                                                        }}
                                                    ></span>}
                                                    {initialLoading && filterFlag && !emptyOrderHistorySearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                </Col>}
                                            </Row>
                                        </Col>
                                        {/* mobile screen search */}
                                        <Col className="px-1 d-block d-md-none" md={12}>
                                            <Row className="justify-content-start align-items-center mobile-padding-search-open-sub px-2">
                                                {!serverErrorOrderHistory && !emptyOrderHistory && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                    {!emptyOrderHistoryFilters &&
                                                        <InputGroup className="search-input-box">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search by Cloud Order ID"
                                                                aria-label="Search by Cloud Order ID"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => {
                                                                setSearchValueTable(e.target.value);
                                                                if (e.target.value && e.target.value.length > 0) {
                                                                setIsAutoRefreshEnabled(false);
                                                                }else{
                                                                setIsAutoRefreshEnabled(true);
                                                                }
                                                                }}                                                              
                                                                ref={searchInputRef}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    }

                                                    {!filterFlag && !emptyOrderHistorySearch && <span 
                                                    className="filter-black cpointer" 
                                                    id="filterblock" 
                                                    onClick={() => {
                                                    setShowFilterBox(!showFilterBox);
                                                    if (!showFilterBox) {
                                                    setIsAutoRefreshEnabled(false);
                                                    } else {
                                                    // Only re-enable auto-refresh if no filters are active
                                                    if (!isFilterOrSearchActive) {
                                                    setIsAutoRefreshEnabled(true);
                                                    }
                                                    }
                                                    }}
                                                    ></span>}
                                                    {initialLoading && filterFlag && !emptyOrderHistorySearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                </Col>}
                                            </Row>
                                        </Col>
                                    </div>
                                    {
                                        !emptyOrderHistorySearch && showFilterBox &&
                                        <OrderHistoryFilterBox serviceId={serviceId} setServiceId={setServiceId} GetTableData={GetTableData} pageNo={pageNo} setPageNo={setPageNo}
                                            pageSize={pageSize} searchValueTable={searchValueTable} orderHistoryDateFrom={orderHistoryDateFrom} orderHistoryDateTo={orderHistoryDateTo}
                                            setOrderHistoryDateFrom={setOrderHistoryDateFrom} setOrderHistoryDateTo={setOrderHistoryDateTo} setOrderStatus={setOrderStatus} orderStatus={orderStatus}
                                            sortCol={sortCol} sortDir={sortDir} setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} orderHistoryServiceLastUsed={orderHistoryServiceLastUsed}
                                            setOrderHistoryServiceLastUsed={setOrderHistoryServiceLastUsed} orderHistoryDateLastUsedDate={orderHistoryDateLastUsedDate} setOrderHistoryOrderTypeLastUsed={setOrderHistoryOrderTypeLastUsed}
                                            setOrderHistoryDateLastUsedDate={setOrderHistoryDateLastUsedDate} orderType={orderType} setOrderType={setOrderType} orderHistoryOrderTypeLastUsed={orderHistoryOrderTypeLastUsed}
                                            orderHistoryDateCustomFromLast={orderHistoryDateCustomFromLast} setOrderHistoryDateCustomFromLast={setOrderHistoryDateCustomFromLast} orderHistoryDateCustomToLast={orderHistoryDateCustomToLast}
                                            setOrderHistoryDateCustomToLast={setOrderHistoryDateCustomToLast} customerLastUsed={customerLastUsed} setCustomerLastUsed={setCustomerLastUsed}
                                            customerId={customerId} setCustomerId={setCustomerId} customerData={customerData} statusLastUsed={statusLastUsed} setStatusLastUsed={setStatusLastUsed}
                                            customerIsAuthenticated={props.customerIsAuthenticated} setCustomerIsAuthenticated={props.setAuthentication} serviceData={serviceData} statusData={statusData} setScrollToDivTop={setScrollToDivTop}
                                            Logout={props.Logout} handleClearFilter={handleClearFilter} setPagesWithInProgress={setPagesWithInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                        />
                                    }
                                    <Container fluid className="mb-5 p-0">
                                        <Row>
                                            <Col>
                                                <div className={`table-responsive ${styles['order-history-history-table']}`}>
                                                    {initialLoading && serverErrorOrderHistory &&
                                                        <div className={styles["no-data-order-history"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-order-history-table-svg" />
                                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && (emptyOrderHistorySearch || emptyOrderHistoryFilters) &&
                                                        <div className={styles["no-data-order-history"]}>
                                                            <img src={EmptyDataTableFilterSearch} className="empty-order-history-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Orders found</h2>
                                                        </div>
                                                    }
                                                    {initialLoading && emptyOrderHistory &&
                                                        <div className={styles["no-data-order-history"]}>
                                                            <img src={RaiseTicketIllustration} className="empty-order-history-table-svg" />
                                                            <h2 className="mt-4 mb-3">No Orders found</h2>
                                                        </div>
                                                    }
                                                    {!serverErrorOrderHistory && !emptyOrderHistoryFilters && !emptyOrderHistory && !emptyOrderHistorySearch &&
                                                        <LazyTable
                                                            d={columns}
                                                            table={table}
                                                            customStyles={customStyles}
                                                            loading={infinityLoading}
                                                            pageNo={pageNo}
                                                            totalRows={totalRows}
                                                            handlePerRowsChange={handlePerRowsChange}
                                                            handlePageChange={handlePageChange}
                                                            styles={styles}
                                                            handleLazyCalls={handleLazyCalls}
                                                            serverErrorUser={serverErrorOrderHistory}
                                                            emptyUser={emptyOrderHistory}
                                                            emptyUserFilters={emptyOrderHistoryFilters}
                                                            emptyUserSearch={emptyOrderHistorySearch}
                                                            setScrollToDivTop={setScrollToDivTop}
                                                            scrollToDivTop={scrollToDivTop}
                                                        />}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </div>
                    </div>}
                    {
                        isDisabled && <AccessDenied />
                    }
                </div>
            </div>
        </>
    )
}

export default OrderHistory