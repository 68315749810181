import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import styles from './gwsManagement.module.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { GET_SERVICES, GET_CUSTOMERS_LIST, GOOGLE_WORKSPACE_SERVICE_MANAGEMENT_ACCOUNT_DETAILS, GOOGLE_WORKSPACE_SUBSCRIPTION_TABLE, GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS, GOOGLE_WORKSPACE_BILLING_TERMS_TYPE, GOOGLE_WORKSPACE_STATUS_TYPE, GOOGLE_WORKSPACE_SUBSCRIPTION_TYPE } from '../../../../../Utils/GlobalConstants';
import { api } from '../../../../../Utils/API';
import Form from "react-bootstrap/Form";
import { Container, Button, Row, Col, OverlayTrigger, Tooltip, Dropdown, Tab, Nav, Tabs } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import serviceLogo from '../../../../../Assets/Images/Illustrations/googleWorkspace.svg';
import SearchDropdown from "../../../../../GlobalComponents/SearchDropdown";
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import EmptyServiceSubscription from '../../../../../Assets/Images/Illustrations/EmptyServiceSubscription.svg';
import EmptyServiceProvision from '../../../../../Assets/Images/Illustrations/EmptyServiceProvision.svg'
import GWSFilterBox from './LocalComponents/FilterBox';
import ExpandableTable from './LocalComponents/ExpandableTable';
import QuantityEditor from '../QuantityEditer';
import Toast from '../../../../../GlobalComponents/Toast';
import GWSUpgrade from './LocalComponents/Actions/GWSUpgrade';
import GWSEditQuantity from './LocalComponents/Actions/GWSEditQuantity';
import GWSDowngrade from './LocalComponents/Actions/GWSDowngrade';
import GWSChangePaymentPlan from './LocalComponents/Actions/GWSChangePaymentPlan';
import GWSRenewalSettings from './LocalComponents/Actions/GWSRenewalSettings';
import GWSChangeStatus from './LocalComponents/Actions/GWSChangeStatus';
import GWSTrialToPaid from './LocalComponents/Actions/GWSTrialToPaid';
import GWSPurchaseAddOns from './LocalComponents/Actions/GWSPurchaseAddOns';
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif";
import LazyTable from './LocalComponents/LazyTable';
import DiscountedPlanPopup from './LocalComponents/DiscountedPlanPopup';

const GWServiceManagement = ({ setOpen, setSelectService, setSelectCategory, Logout }) => {
    const renderTooltip = (props) => (
        <Tooltip {...props}>{props.infoText}</Tooltip>
    );

    const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Manage") ? true : false);
    const [isCloudAdmin, setIsCloudAdmin] = useState(localStorage.getItem("curentRole") && localStorage.getItem("curentRole").toLowerCase().includes("cloud admin view") ? true : false);

    const location = useLocation();
    const navigate = useNavigate();
    const [copied, setCopied] = useState("Click to copy");                          // Copy Use State
    const [searchIconFlag, setSearchIconFlag] = useState(location.state?.planName !== null ? true : false);                    // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(location.state?.planName !== null ? location.state?.planName : null);                 // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(true);                            // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                      // showing filter box
    const searchInputRef = useRef();                                                // Search Input Box

    const alphanumericRegex = /^[a-zA-Z0-9-]{0,50}$/; // RegEx for PO Number

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                        // table active page number
    const [pageSize, setPageSize] = useState(15);                                    // table active page size
    const [totalRows, setTotalRows] = useState(0);                                  // table total count of data from api 
    const [columns, setColumns] = useState([]);                                     // columns used to display column headers in the react data table component
    const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
    let ColumnName = ["Plan", "Assigned Seats", "Quantity", "Billing Term", "Start Date", "Renewal Date", "Subscription Type", "Status", "Info",
        "Actions"];
    let ColumnNameReadOnly = ["Plan", "Assigned Seats", "Quantity", "Billing Term", "Start Date", "Renewal Date", "Subscription Type", "Status", "Info"];
    // Order Confirmation Details Table
    // table headers used to make object of react data table component 


    // Order Confirmation Details Table
    let detailsColumn = ["Plan", "Billing Term", "Unit Price","Renewal Price (Ex GST)*", "Quantity", "Sub-Total Price"];
    const [detailsTable, setDetailsTable] = useState([]);
    const [detailsTableColumn, setDetailsTableColumn] = useState([]);


    const [serverErrorUser, setServerErrorUser] = useState(false);                   // server error check state in table during api call to load data
    const [emptyUser, setEmptyUser] = useState(false);                               // empty table check state in table during api call to load data
    const [emptyUserSearch, setEmptyUserSearch] = useState(false);                   // empty search response check state in table during api call to load data
    const [emptyUserFilters, setEmptyUserFilters] = useState(true);                 // empty filter response check state in table during api call to load data
    const [loading, setLoading] = useState(false);
    const [provisionRefreshIconFlag, setProvisionRefreshIconFlag] = useState(false);


    const [customerId, setCustomerId] = useState(location.state?.customerId !== null ? location.state?.customerId : "");
    const [resellerId, setResellerId]
        = useState((location.state !== null && location.state !== "") ? location.state.resellerId : "");  // storing reseller id

    const [subscriptionId, setSubscriptionId] = useState(null);

    // Get Table Data Function Parameters
    const [status, setStatus] = useState(["ACTIVE", "SUSPENDED", "PENDING"]);             // getTableData() status
    const [planType, setPlanType] = useState(null);                                     // getTableData() subscription
    const [billingTerm, setBillingTerm] = useState("");
    const [sortCol, setSortCol] = useState("sortingStartDate");                                // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("DESC");                                     // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending
    const [isDescStartDate, setIsDescStartDate] = useState(true);                       // sort state check for start date column
    const [isDescRenewalDate, setIsDescRenewalDate] = useState(null);                   // sort state check for renewal date column
    const [serviceId, setServiceId] = useState(null);
    const [serviceData, setServiceData] = useState(null);                               // data in service dropdown
    const [customerData, setCustomerData] = useState([]);                               // data in status dropdown
    const [modalOpen, setModalOpen] = useState(false);
    const [additionalDetails, setAdditionalDetails] = useState(null);
    const [editQuantityModalOpen, setEditQuantityModalOpen] = useState(false);
    const [actionsSubscriptionId, setActionsSubscriptionId] = useState(location.state?.subscriptionId !== null ? location.state?.subscriptionId : null);
    const [actionsPlanName, setActionsPlanName] = useState(null);

    // filterbox usestates
    const [subscriptionTypeData, setSubscriptionTypeData] = useState(null);                             // data in service dropdown
    const [statusData, setStatusData] = useState(null);                               // data in status dropdown
    const [billingTypeData, setBillingTypeData] = useState(null);                     // data in account type dropdown

    //lazy loading
    const [infinityLoading, setInfinityLoading] = useState(false);                   // loading state while table loads
    const [scrollToDivTop, setScrollToDivTop] = useState(false);

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);
    const [toastError, setToastError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    // Search Value for Service Name
    const [serviceNameDefaultValue, setServiceNameDefaultValue] = useState("Select Service Name");
    const [serviceNameSearchValue, setServiceNameSearchValue] = useState();
    const [serviceName, setServiceName] = useState([]);
    const [serviceNameFiltered, setServiceNameFiltered] = useState(serviceName);
    const updatedServiceName = [];

    // Search Value for Customer Name
    const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState("Select Customer Name");
    const [customerNameSearchValue, setCustomerNameSearchValue] = useState();
    const [customerName, setCustomerName] = useState([]);
    const [customerNameFiltered, setCustomerNameFiltered] = useState(customerData);
    const updatedCustomerName = [];

    const [initialSubscriptionTableCall, setInitialSubscriptionTableCall] = useState(null);

    const [editingQuantityRowId, setEditingQuantityRowId] = useState(null);
    const [editedQuantity, setEditedQuantity] = useState(null);
    const [isEditingQuantity, setIsEditingQuantity] = useState(false);
    const [minQuantity, setMinQuantity] = useState(null);
    const [maxQuantity, setMaxQuantity] = useState(null);
    const editQuantityRef = useRef();
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);

    const [updatedQuanity, setUpdatedQuanity] = useState(null);
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);
    const [editQuantityActionPopupModalOpen, setEditQuantityActionPopupModalOpen] = useState(false);
    const [upgradeActionPopupModalOpen, setUpgradeActionPopupModalOpen] = useState(false);
    const [downgradeActionPopupModalOpen, setDowngradeActionPopupModalOpen] = useState(false);
    const [changePaymentPlanActionPopupModalOpen, setChangePaymentPlanActionPopupModalOpen] = useState(false);
    const [renewalSettingsActionPopupModalOpen, setRenewalSettingsActionActionPopupModalOpen] = useState(false);
    const [changeStatusActionPopupModalOpen, setChangeStatusActionPopupModalOpen] = useState(false);
    const [trialToPaidActionPopupModalOpen, setTrialToPaidActionPopupModalOpen] = useState(false);
    const [purchaseAddOnsActionPopupModalOpen, setPurchaseAddOnsActionPopupModalOpen] = useState(false);

    const [setUpBillingActionFlag, setSetUpBillingActionFlag] = useState(false);

    const [subscriptionTableAPIData, setSubscriptionTableAPIData] = useState(null);
    const [editQuantityActionsValue, setEditQuantityActionsValue] = useState(null);
    const [newQuantity, setNewQuantity] = useState(null);

    let ColumnNameSubscriptionTable = ["Billing Term", "Quantity", "Auto Renew", "RRP (Inc GST)", "Reseller Price (Ex GST)"];   // table headers used to make object of react data table component 
    const [columnsSubscriptionTable, setColumnsSubscriptionTable] = useState([]);                                       // columns used to display column headers in the react data table component
    const [tableSubscriptionTable, setTableSubscriptionTable] = useState([]);
    
    // Subscription Details and discounted plan details Popup UseStates
    const [discountedPlanDetails, setDiscountedPlanDetails] = useState()
    const [subscriptionDetails, setSubscriptionDetails] = useState()
    const [subscriptionType, setSubscriptionType] = useState(null)
    const [isSubscriptionDetailsPopupOpen, setIsSubscriptionDetailsPopupOpen] = useState(false);                        // Subscription Details Popup Box
    const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false)
    const [subscriptionDetailsIsTrial, setSubscriptionDetailsIsTrial] = useState(null);

    const [actionCompletedRefreshSubscriptionTable, setActionCompletedRefreshSubscriptionTable] = useState(false)
    const [poNumber, setPoNumber] = useState(null);
    const [filterApplied, setFilterApplied] = useState(false);                        // filter in gettabledata

    const defaultServiceName = "Google Workspace";
    const defaultCategoryName = "Cloud Sales Enquiry";
    const [autoRefreshInterval, setAutoRefreshInterval] = useState(5000);
    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(false);
    const [isFilterOrSearchActive, setIsFilterOrSearchActive] = useState(false);
    const [isActionInProgress, setIsActionInProgress] = useState(false);
    const [isEditingInProgress, setIsEditingInProgress] = useState(false);
    const [isSorting, setIsSorting] = useState(false);
    const [hasPendingStatus, setHasPendingStatus] = useState(false);
    const [pagesWithPending, setPagesWithPending] = useState(new Set());
    const [loadedPages, setLoadedPages] = useState(new Set([1]));
    const [actionPageNo, setActionPageNo] = useState(null);
    const [tableData, setTableData] = useState({});


    const clearExistingData = () => {
    setTable([]);
    setTableData({});
    setTotalRows(0);
    };

    const handleEditQuantitySave = (row) => {
        setEditQuantityModalOpen(true);
    };

    const handleEditQuantityClose = () => {
        setEditingQuantityRowId();
        setEditedQuantity(null);
        setIsEditingQuantity(false);
        setMinQuantity(null);
        setMaxQuantity(null);
        setIsEditingInProgress(false);
        if (!isFilterOrSearchActive) {
        setIsAutoRefreshEnabled(true);
        }
    };

    const handleEditQuantityClick = (row) => {
        setEditedQuantity(row["Quantity"]);
        setEditingQuantityRowId(row.subscriptionId);
        setIsEditingInProgress(true);
        setActionsSubscriptionId(row.subscriptionId);

        api.get(`${GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS + row.subscriptionId}?actionType=editquantity`, config)
            .then(resp => {
                setLoading(false);
                setMaxQuantity(resp.data.maxQuantity);
                setMinQuantity(resp.data.minQuantity);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    // Copied to Clipboard Function
    const copyToClipboard = (domainName) => {
        navigator.clipboard.writeText(domainName);
        setCopied("Copied!");
        setTimeout(() => {
            setCopied("Click to copy");
        }, 1900);
    };

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className={styles['threeDotAnchorTag']}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className={styles["threedots"]} />
        </a>
    ));

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetAllServices() {

        await api.get(GET_SERVICES, config)
            .then(resp => {
                let serviceArray = []
                resp.data.map((service) => {
                    if (service.serviceType == "ONLINE") {
                        serviceArray.push(service);
                    }
                })
                setServiceData(serviceArray);
                setServiceNameFiltered(serviceArray);

                for (let i in resp.data) {
                    if (resp.data[i].integrationCode === "googleworkspace") {
                        setServiceId(resp.data[i].id);
                        setServiceNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }

                for (let i in resp.data) {
                    if (!serviceName.includes(resp.data[i].name)) {
                        serviceName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(`${GET_CUSTOMERS_LIST}?requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setCustomerData(resp.data);
                setCustomerNameFiltered(resp.data);

                for (let i in resp.data) {
                    if (resp.data[i].id === customerId) {
                        setCustomerNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }

                for (let i in resp.data) {
                    if (!customerName.includes(resp.data[i].name)) {
                        customerName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // function to call all additional details of Subscription Customer
    async function GetAdditionalDetails() {
        await api.get(`${GOOGLE_WORKSPACE_SERVICE_MANAGEMENT_ACCOUNT_DETAILS}?customerId=${customerId}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setAdditionalDetails(resp.data);
                if (resp.data.isProvision) {
                    setInitialSubscriptionTableCall(true);
                    setProvisionRefreshIconFlag(false);
                }
                else {
                    setInitialSubscriptionTableCall(false);
                    setProvisionRefreshIconFlag(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setInitialSubscriptionTableCall(false);
                }

            });
    };

    const handleIncrease = (qty) => {
        if (editedQuantity <= maxQuantity) {
            setEditedQuantity(parseInt(qty) + 1);
            setIsEditingQuantity(true);
        } else {
            setEditedQuantity(parseInt(qty));
        }
    }

    const handleDecrease = (qty) => {
        if (editedQuantity >= minQuantity) {
            setEditedQuantity(parseInt(qty) - 1);
            setIsEditingQuantity(true);
        } else {
            setEditedQuantity(parseInt(qty));
        }
    }

    // Update Edit Quantity
    async function UpdateEditQuantity() {
        setConfirmLoadingFlag(true);
        let requestBody = {
            "quantity": parseInt(editedQuantity),
            "subscriptionId": parseInt(subscriptionId),
            "poNumber": poNumber
        };

        await api.put(GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS + "change-quantity", requestBody, config)
            .then(resp => {
                if (resp.status = 200) {
                    setEditQuantityModalOpen(false);
                    setConfirmLoadingFlag(false);

                    setToastMessage("Quantity Updated Successfully");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setEditingQuantityRowId();
                    setEditedQuantity(null);
                    setIsEditingQuantity(false);
                    setPoNumber(null);
                    setActionCompletedRefreshSubscriptionTable(true);
                    setIsEditingInProgress(false);
                if (!isFilterOrSearchActive) {
                    setIsAutoRefreshEnabled(true);
                }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setIsAutoRefreshEnabled(false);
                setEditQuantityModalOpen(false);
                setConfirmLoadingFlag(false);
                setToastMessage("We encountered an issue while updating the quantity. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
            })
    }

    function UpdateEditQuantityError() {
        setEditQuantityModalOpen(false);

    }

    // Function to add data in View Subscription Details Popup
    async function GetSubscriptionDetailsPopup(subscriptionid) {
        setSubscriptionDetailIsLoadingFlag(true);
        await api
            .get(`${GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS + subscriptionid}?requestedResellerCode=${resellerId}`, config)
            .then((resp) => {
                setDiscountedPlanDetails(resp.data);
                setSubscriptionDetails(resp.data)
                setSubscriptionType(resp.data.subscriptionType)
                setSubscriptionDetailsIsTrial(resp.data.isTrial);
                setSubscriptionDetailIsLoadingFlag(false);
            })
            .catch((error) => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setSubscriptionDetailIsLoadingFlag(false);
                }
            });
    }

    //for actions core data 
    async function GetActionsData(subscriptionId,actionType) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        (actionType === undefined || actionType === null) ? actionType = "" : actionType = actionType;
        await api.get(`${GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS}${subscriptionId}?actionType=${actionType}`, config)
            .then(resp => {
                setLoading(false);
                if (resp.status == 204) {

                }
                // handle success
                else {
                    let f = [];
                    setSubscriptionTableAPIData(resp.data)
                    setMaxQuantity(resp.data.maxQuantity);
                    setMinQuantity(resp.data.minQuantity);
                    setNewQuantity(resp.data.quantity);
                    setEditQuantityActionsValue(resp.data.quantity);
                    f.push
                        (
                            {
                                "Billing Term": resp.data.billingTerm,
                                "Assigned Seats": resp.data.assignedSeat,
                                "Quantity": resp.data.quantity,
                                "Status": resp.data.status === 'ACTIVE' ? (
                                    <div className={`${styles['status-gws']}`}>
                                        <span className="status-active"></span>
                                        <div className="py-1 text-muted text-small">Active</div>
                                    </div>
                                ) : resp.data.status === 'CANCELLED' ? (
                                    <div className={`${styles['status-gws']}`}>
                                        <span className="status-suspended"></span>
                                        <div className="py-1 text-muted text-small">Cancelled</div>
                                    </div>
                                ) : resp.data.status === 'PENDING' ? (
                                    <div className={`${styles['status-gws']}`}>
                                        <span className="status-pending"></span>
                                        <div className="py-1 text-muted text-small">Pending</div>
                                    </div>
                                ) : (
                                    resp.data.status
                                ),
                                "Auto Renew": (resp.data.autoRenew ? "Yes" : "No"),
                                "Subscription Type": resp.data.subscriptionType,
                                "RRP (Inc GST)": `$${resp.data.rrp}`,
                                "Reseller Price (Ex GST)": `$${resp.data.price}`,
                                "Start Date": resp.data.startDate,
                                "Renewal Date": resp.data.renewalDate,
                            }
                        );
                    setTimeout(() => {
                        setTableSubscriptionTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnNameSubscriptionTable.map((td) => {
                        if (td === "RRP (Inc GST)" || td === "Reseller Price (Ex GST)" || td === "Quantity") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                right: true,
                            })
                        }
                        else if (td === "Billing Term") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                                    >
                                        <span className="infoIcon-support-table ml-1"></span>
                                    </OverlayTrigger></div>,
                                selector: row => <div className="text-wrap">
                                    {row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                                allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }

                    })
                    setColumnsSubscriptionTable(d);     // Setting Column Data

                }

            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    async function GetSubscriptionDetails() {
        setLoading(true);
        setSubscriptionId(editingQuantityRowId);
        await api.get(`${GOOGLE_WORKSPACE_SUBSCRIPTION_DETAILS + editingQuantityRowId}?actionType=editquantity`, config)
            .then(resp => {
                setLoading(false);
                setMaxQuantity(resp.data.maxQuantity);
                setMinQuantity(resp.data.minQuantity);
                let f = [];
                f.push
                    (
                        {
                            "Plan": resp.data.planName,
                            "Billing Term": resp.data.billingTerm,
                            "Unit Price": `$${resp.data.price}`,
                            "Renewal Price (Ex GST)*":`$${resp.data.rrpExclusiveTax}`,
                            "Quantity": editedQuantity,
                            "Sub-Total Price": "$" + Math.round((((resp.data.price) * parseInt(editedQuantity)) + Number.EPSILON) * 100) / 100
                        }
                    );
                setTimeout(() => {
                    setDetailsTable(f);         // Setting Table Data
                }, 50);
                let d = [];
                detailsColumn.map((td) => {
                    if (td === "Unit Price" || td === "Quantity" || td === "Sub-Total Price" || td==="Renewal Price(Ex GST)") {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            right: true,
                            grow: 1
                        })
                    }
                    else if (td === "Billing Term") {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Billing Term (Commitment Term)</Tooltip>}
                                >
                                    <span className="infoIcon-support-table ml-1"></span>
                                </OverlayTrigger></div>,
                            selector: row => <div className="text-wrap">
                                {row[`${td}`]}</div>,
                            left: true,
                            grow: 2,
                            allowOverflow: true,
                        })
                    }
                    else {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            left: true,
                            grow: (td === "Billing Term") ? 3 : 1.5,
                        })
                    }
                })
                setDetailsTableColumn(d);     // Setting Column Data
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    useEffect(() => {
        if (editQuantityModalOpen) {
            GetSubscriptionDetails();
        }
    }, [editQuantityModalOpen]);

    let d = [];
    (isreadOnly ? ColumnNameReadOnly : ColumnName).map((td) => {
        if (td === "Status" || td === "Info" || td === "Actions") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
            })
        }
        else if (td === "Actions") {
            d.push({
                id: `${td}`,
                name: <div className='d-none-mobile'>{td}</div>,
                selector: row => <div className="text-wrap d-none-mobile">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
            })
        }
        else if (td === "Plan") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2.8,
                allowOverflow: true,
            })
        }
        else if (td === "Subscription Type") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2,
                allowOverflow: true,
            })
        }
        else if (td === "Quantity") {
            d.push({
                id: `${td}`,
                name: <div className='quantity-col'>{td}</div>,
                cell: (row) => {
                    let isActive = (!(row.IsTrial) && row.Status && row.Status.props && row.Status.props.children[1].props.children) === 'Active';
                    return isActive ?
                        editingQuantityRowId == row.subscriptionId ? (
                            <div className='d-flex flex-wrap justify-content-between my-2'>
                                <div className="quantityEditor">
                                    <QuantityEditor
                                        editedQuantity={editedQuantity}
                                        editQuantityRef={editQuantityRef}
                                        setEditedQuantity={setEditedQuantity}
                                        handleIncrease={handleIncrease}
                                        handleDecrease={handleDecrease}
                                        setIsEditingQuantity={setIsEditingQuantity}
                                        maxQuantity={maxQuantity}
                                        minQuantity={minQuantity}
                                        updatedQuanity={row["Quantity"]}
                                    />

                                    {/* {isEditingQuantity ? (
                                        <div className='mx-1' onClick={() => handleEditQuantitySave(row)}>
                                            <span className="quantity-tick" />
                                        </div>
                                    ) : (
                                        <div className='mx-1'>
                                            <span className="tick-grey" />
                                        </div>
                                    )} */}
                                     {isEditingQuantity ? (
                                        editedQuantity <= maxQuantity && editedQuantity >= minQuantity ? (
                                            <div className='mx-1' onClick={() => handleEditQuantitySave(row)}>
                                                <span className="quantity-tick" />
                                            </div>
                                        ) : (
                                            <div className='mx-1'>
                                                <span className="tick-grey" />
                                            </div>
                                        )
                                    ) : (
                                        <div className='mx-1'>
                                            <span className="tick-grey" />
                                        </div>
                                    )}
                                    <div className='mx-1' onClick={handleEditQuantityClose}>
                                        <span className="quantity-cross">&nbsp;</span>
                                    </div>
                                </div>
                                <div className='text-disabled col-12'>
                                    <span className="text-small">Min {minQuantity}, </span>
                                    <span className="text-small">Max {maxQuantity}</span>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {row["Quantity"]} <span onClick={() => handleEditQuantityClick(row)} className={isreadOnly ? 'd-none' : "quantity-active px-4"}></span>
                            </div>
                        ) : (
                            <div>
                                {row["Quantity"]}
                                <span className={isreadOnly ? 'd-none' : "quantity-disable px-4 d-none-mobile"}></span>
                            </div>
                        )
                },
                left: true,
                allowOverflow: true,
                grow: 2.8,
            })
        } else if (td === "Start Date") {
            d.push({
                id: `${td}`,
                name: <div>
                    {isDescStartDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortingStartDate"); setIsDescRenewalDate(null); setIsSorting(true); setIsAutoRefreshEnabled(false); }}></span></>}
                    {isDescStartDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescStartDate(false); setSortCol("sortingStartDate"); setIsSorting(true); setIsAutoRefreshEnabled(false); }}></span></>}
                    {isDescStartDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescStartDate(true); setSortCol("sortingStartDate"); setIsSorting(true); setIsAutoRefreshEnabled(false); }}></span></>}
                </div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2,
            })
        }
        else if (td === "Renewal Date") {
            d.push({
                id: `${td}`,
                name: <div>
                    {isDescRenewalDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescRenewalDate(true); setSortCol("sortingRenewalDate"); setIsDescStartDate(null); }}></span></>}
                    {isDescRenewalDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("ASC"); setIsDescRenewalDate(false); setSortCol("sortingRenewalDate"); }}></span></>}
                    {isDescRenewalDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setScrollToDivTop(true); setSortDir("DESC"); setIsDescRenewalDate(true); setSortCol("sortingRenewalDate"); }}></span></>}
                </div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 2.5,
            })
        } else if (td === "Billing Term") {
            d.push({
                id: `${td}`,
                name: <div>{td}
                    <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip({
                            infoText: "Billing Term (Commitment Term)",
                        })}
                    >
                        <span className="infoIcon-support-table ml-1"></span>
                    </OverlayTrigger></div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 3,
                allowOverflow: true,
            })
        } else {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
            })
        }

    })

    // Get Table Data with Pagination and Search Functionality
   async function GetTableData(page, newPerPage, search, status, planType, billingTerm, sortCol, sortDir, appendData, isFilter = false) {
        setLoading(true);
        (search === undefined || search === null) ? search = "" : search = search;
        (status === undefined || status === null) ? status = "" : status = status;
        (billingTerm === undefined || billingTerm === null) ? billingTerm = "" : billingTerm = billingTerm;
        (planType === undefined || planType === null) ? planType = "" : planType = planType;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        try {
        const resp = await api.get(`${GOOGLE_WORKSPACE_SUBSCRIPTION_TABLE}?status=${status}&pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&billingTerm=${billingTerm}&customerId=${customerId}&subscriptionType=${planType}&sortCol=${sortCol}&sortDir=${sortDir}&requestedResellerCode=${resellerId}`, config);
        
        setLoading(false);
        setInfinityLoading(false);

        if (resp.status == 204) {
                 setPagesWithPending(new Set());
                    if(filterFlag){
                        isFilter = filterFlag
                        isFilter = search != "" ? !isFilter : isFilter; 
                    }
                    if (search === "" && ((status === "" && billingTerm === "" && planType === "") || (status.length === 0 && billingTerm.length === 0 && planType.length === 0) )) {
                        setShowFilterBox(false);
                        setEmptyUser(true);
                    }
                    else if ((status != "" || billingTerm != "" || planType != "") && isFilter) {
                        setEmptyUserFilters(true);
                    }
                    else if (search != "" && !isFilter) {
                        setEmptyUserSearch(true);
                    }
                    }
                // handle success
                else {
                const hasPending = resp.data.content.some(item => item.status.toUpperCase() === 'PENDING');
                updatePendingPages(page, hasPending);
                setHasPendingStatus(hasPending);
                setLoadedPages(prev => new Set(prev).add(page));
                resetErrorStates();
                setTotalRows(resp.data.totalCount);
                const formattedData = resp.data.content.map((td) => createTableRow(td));
                const uniqueData = removeDuplicates(formattedData, 'subscriptionId');
                updateTableData(page, uniqueData, newPerPage, appendData, isFilter);
                setTable(prevTable => [...prevTable]);
                }
        } catch (error) {
                setLoading(false);
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                Logout();
                } else {
                setServerErrorUser(true);
                }
        }
    }

    function createTableRow(td) {
    return {
        "Plan": <>
            {td.planName}
            <div className={`py-1 ${styles['gws-vendor-id']}`}>{td.vendorSubscriptionId}</div>
        </>,
        "subscriptionId": td.subscriptionId,
        "Quantity": td.quantity,
        "Assigned Seats": td.assignedSeat,
        "Billing Term": td.billingTerm,
        "IsTrial": td.isTrial,
        "Start Date": td.startDate,
        "Renewal Date": td.renewalDate,
        "has Add On": td.hasAddOn,
        "Subscription Type": td.planType,
        "Status": getStatusComponent(td.status,td.isSuspendReasonFlag),
        "Info": <span
            className="infoIcon"
            onClick={() => {
                setIsSubscriptionDetailsPopupOpen(true);
                GetSubscriptionDetailsPopup(td.subscriptionId);
            }}
        >
            &nbsp;
        </span>,
        "Actions": getActionsComponent(td)
    };
    }

    function removeDuplicates(array, key) {
        return array.filter((item, index, self) =>
        index === self.findIndex((t) => t[key] === item[key])
        );
    }

    const refreshData = () => {
    GetAdditionalDetails();
    for (let page of pagesWithPending) {
        if (loadedPages.has(page)) {
            GetTableData(page, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir, true);
        }
    }
    };

    function getStatusComponent(status,isSuspendReasonFlag) {
    const statusMap = {
        'ACTIVE': { className: 'status-active', text: 'Active' },
        'SUSPENDED': { className: 'status-suspended', text: 'Suspended' },
        'PENDING': { className: 'status-pending', text: 'Pending' },
        'CANCELLED': { className: 'status-failed', text: 'Cancelled' },
    };

    const statusInfo = statusMap[status] || { className: '', text: status };

    if(!isSuspendReasonFlag){
        return (
            <div className={`${styles['status-gws']}`}>
                <span className={statusInfo.className}></span>
                <div className="py-1 text-muted text-small">{statusInfo.text}</div>
            </div>
        );
    }
    else{
        return(
            <div className={`${styles['status-gws']}`}>
                <span className="status-suspended"></span>

                {/* { suspendedFlag && */}
                <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip({
                        infoText: "Subscription is suspended until customer accepts Google’s new Terms of Service. Please ask the customer to login into Google Admin portal and accept Terms of Service.",
                    })}
                >
                    <span className="infoIcon-suspended ml-1 mt-1"></span>
                </OverlayTrigger>
                {/* }  */}
                <div className="py-1 text-muted text-small">Suspended</div>
            </div>
        )
    }
    
    }

    function getActionsComponent(td) {
        if (td.status === "PENDING" || td.status === "CANCELLED" || td.actions.length === 0 || (td.isSuspendReasonFlag)) {
            return <span className={`${styles['threedots']} three-dots-icon-disabled`} />;
        }
        else{
            return (
            <Dropdown drop="start" onToggle={handleDropdownToggle}>
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu size="sm">
                    {td.actions.map((items) => (
                        <Dropdown.Item key={items.id} onClick={() => handleActionClick(items, td)}>
                            {getActionIcon(items.id)} {items.value}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
        }
        }

    function handleDropdownToggle(isOpen) {
    setIsAutoRefreshEnabled(!isOpen);
    }

    // we have common API for the all actions where we pass subscription id and actionType to the API.
    function handleActionClick(items, td) {
    // when the action is edit quantity along with subscription id we are sending actionType as edit quantity to distinguish the action and calculate the renewal price and unit price based on action.
    if (items.id === 'editquantity') {                  
        GetActionsData(td.subscriptionId, items.id);  
    } 
   // when the action is changeStatus along with subscription id we are sending actionType as reactivate/suspend based on the status to distinguish the action and calculate the renewal price and unit price based on action.
    else if (items.id === 'changestatus') {
       let ActionType=""
        if (td.status === 'ACTIVE') { // when the status is Active we send actionType as reactivate and calculate the renewal price and unit price.
            ActionType = "reactivate";
        } else if (td.status === 'SUSPENDED') { // when the status is Suspended we send actionType as suspend and calculate the renewal price and unit price.
            ActionType = "suspend";
        } 
        GetActionsData(td.subscriptionId,ActionType); 
    } 
    else {
        GetActionsData(td.subscriptionId);  
    }
    handleActionsClick(items.id);
    setActionsSubscriptionId(td.subscriptionId);
    setActionsPlanName(td.planName);
    setIsActionInProgress(true);
    setIsAutoRefreshEnabled(false);
    }

    function updatePendingPages(page, hasPending) {
    setPagesWithPending(prev => {
        const newSet = new Set(prev);
        if (hasPending) {
            newSet.add(page);
        } else {
            newSet.delete(page);
        }
        return newSet;
    });
    }

    function getActionIcon(id) {
    const iconMap = {
        'editquantity': 'Edit-Icon',
        'changestatus': 'change-status',
        'upgrade': 'upgrade',
        'downgrade': 'downgrade',
        'changepaymentplan': 'change-payment-plan',
        'setupbilling': 'renewal-settings',
        'renewal-settings': 'renewal-settings',
        'trial-to-paid': 'trial-to-paid',
        'purchase-add-ons': 'purchase-addon'
    };

    const iconClass = iconMap[id] || 'renewal-settings';
    return <span className={iconClass}></span>;
    }

    function resetErrorStates() {
    setServerErrorUser(false);
    setEmptyUser(false);
    setEmptyUserSearch(false);
    setEmptyUserFilters(false);
    }

    function updateTableData(page, uniqueData, newPerPage, appendData, isFilter) {
    setTableData(prevData => ({
        ...prevData,
        [page]: uniqueData
    }));

    setTable(prevTable => {
        if (isFilter || page === 1) {
            // Replace existing data for the current page
            return uniqueData;
        } else if (appendData) {
            // Append new data for lazy loading
            return [...prevTable, ...uniqueData];
        } else {
            // Update specific page data
            const startIndex = (page - 1) * newPerPage;
            const endIndex = startIndex + uniqueData.length;
            const updatedTable = [...prevTable];
            updatedTable.splice(startIndex, endIndex - startIndex, ...uniqueData);
            return updatedTable;
        }
    });
    }
    // Function to call all dropdown values in SubscriptionType Section in Filter Box
    async function GetAllSubscriptionType() {
        await api.get(GOOGLE_WORKSPACE_SUBSCRIPTION_TYPE, config)
            .then(resp => {
                setSubscriptionTypeData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(GOOGLE_WORKSPACE_STATUS_TYPE, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    // Function to call all dropdown values in Account Type Section in Filter Box
    async function GetAllBillingType() {
        await api.get(GOOGLE_WORKSPACE_BILLING_TERMS_TYPE, config)
            .then(resp => {
                setBillingTypeData(resp.data);
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }

            });
    };

    const handleActionsClick = (id) => {
        setIsActionInProgress(true);
        setIsAutoRefreshEnabled(false);
        switch (id) {
            case 'editquantity':
                setEditQuantityActionPopupModalOpen(true);
                break;
            case 'changestatus':
                setChangeStatusActionPopupModalOpen(true);
                break;
            case 'upgrade':
                setUpgradeActionPopupModalOpen(true);
                break;
            case 'downgrade':
                setDowngradeActionPopupModalOpen(true);
                break;
            case 'changepaymentplan':
                setChangePaymentPlanActionPopupModalOpen(true);
                setSetUpBillingActionFlag(false);
                break;
            case 'setupbilling':
                setChangePaymentPlanActionPopupModalOpen(true);
                setSetUpBillingActionFlag(true);
                break;
            case 'renewal-settings':
                setRenewalSettingsActionActionPopupModalOpen(true);
                break;
            case 'trial-to-paid':
                setTrialToPaidActionPopupModalOpen(true);
                break;
            case 'purchase-add-ons':
                setPurchaseAddOnsActionPopupModalOpen(true);
                break;
            default:
        }
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
    setInfinityLoading(true);
    const nextPage = pageNo + 1;   
    if (!tableData[nextPage]) {
        await GetTableData(nextPage, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir, true);
    } else {
        setTable(prevTable => {
            const newTable = [...prevTable, ...tableData[nextPage]];
            return removeDuplicates(newTable, 'subscriptionId');
        });
    }
    setPageNo(nextPage);
    setInfinityLoading(false);
    };

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
    if (searchValueTable !== undefined && searchValueTable !== null) {
        const getData = setTimeout(() => {
            if (searchValueTable.length > 0) {
                setScrollToDivTop(true);
                setPageNo(1);
                setTable([]);
                setTableData({});
                GetTableData(1, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir);
                setIsAutoRefreshEnabled(false); 
                setPagesWithPending(new Set());
            } else {
                setScrollToDivTop(true);
                setPageNo(1);
                setTable([]);
                setTableData({});
                GetTableData(1, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir);
                setIsAutoRefreshEnabled(true);
                setPagesWithPending(new Set());
            }
        }, 800);
        return () => clearTimeout(getData);
    }
    }, [searchValueTable]);

    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '300px'
            },
        },
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa',
                zIndex: 3
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    // React Data Table Small Custom Styles
    const customStylesTableSmall = {
        tableWrapper: {
            style: {
                minHeight: '100px'
            },
        },
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa'
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };


    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir);
        setPageNo(pageNo);
    };

    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(pageNo, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir);
    };

    // Use Effect to get all the services in filter service dropdown
    useEffect(() => {
        setEmptyUser(false);
        clearExistingData();
        GetAdditionalDetails();
        GetAllServices();
        GetAllCustomers();
    }, [customerId]);

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
        if (initialSubscriptionTableCall) {
            clearExistingData();
            setPageNo(1);
            if (filterApplied){
                GetTableData(1, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir, false, true);
            }else{
                GetTableData(1, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir, false, false);
            }
            if (isSorting) {
            setIsSorting(false);
            if (!isFilterOrSearchActive && hasPendingStatus) {
                setIsAutoRefreshEnabled(true);
            }
            }
        }
    }, [isDescRenewalDate, isDescStartDate, initialSubscriptionTableCall]);

    // Refreshing Subscription Table on Action Completion
    useEffect(() => {
        if (actionCompletedRefreshSubscriptionTable) {
            GetAdditionalDetails();
            const actionedItemIndex = table.findIndex(item => item.subscriptionId == actionsSubscriptionId);
            const calculatedPageNo  = Math.floor(actionedItemIndex / pageSize) + 1;
            GetTableData(calculatedPageNo, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir);
            setActionCompletedRefreshSubscriptionTable(false);
        }
    }, [actionCompletedRefreshSubscriptionTable])


    // Renders when service ID Changes
    useEffect(() => {
        if (serviceId) {
            let url = "";
            let integrationCode = "";
            serviceData && serviceData.map((data) => {
                if (data.id == serviceId) {
                    integrationCode = data.integrationCode
                }
            })
            switch (integrationCode) {
                case "softlayer": // IBM Cloud
                    navigate("/management-console/ibm", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "microsoftazure": // Microsoft CSP - Azure
                    navigate("/management-console/azure", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "cloudcare": // Avast Cloud Care
                    navigate("/management-console/avast", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "acronis1": //  Acronis Cyber Cloud
                    navigate("/management-console/acronis", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "googleworkspace": // Google Workspace
                    navigate("/management-console/gws", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "GCP":  // Google Cloud Platform
                    navigate("/management-console/gcp", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                default: // Microsoft CSP
                    navigate("/management-console/csp", { state: { customerId: customerId, resellerId: resellerId } });
                    break;
            }
        }
    }, [serviceId]);

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    //Scroll to top on opening of page
    useEffect(() => {
        window.scrollTo(0, 0);
        GetAllSubscriptionType();
        GetAllBillingType();
        GetAllStatuses();
    }, [])

    useEffect(() => {
        if (location.state.subscriptionId) {
            GetActionsData(location.state.subscriptionId);
            if (location.state.isRenewalSettings) {
                setRenewalSettingsActionActionPopupModalOpen(true);
            }
            else {
                setChangeStatusActionPopupModalOpen(true);
            }
        }

    }, [location.state?.subscriptionId])

    useEffect(() => {
    // Determine if filter or search is active
    // const filterActive = status || billingTerm || planType;
    const filterActive = billingTerm || planType;
    const searchActive = searchValueTable && searchValueTable.length > 0;
    setIsFilterOrSearchActive(searchActive);
    }, [status, billingTerm, planType, searchValueTable]);

    useEffect(() => {
    console.log(isAutoRefreshEnabled , initialSubscriptionTableCall , !isFilterOrSearchActive , pagesWithPending.size > 0 , !isActionInProgress , !isEditingInProgress , !isSorting, "auto refersh Data");
    if (isAutoRefreshEnabled && initialSubscriptionTableCall && !isFilterOrSearchActive && pagesWithPending.size > 0 && !isActionInProgress && !isEditingInProgress && !isSorting) {
        const intervalId = setInterval(() => {
            refreshData();
        }, autoRefreshInterval);

        return () => clearInterval(intervalId);
    }
    }, [isAutoRefreshEnabled, autoRefreshInterval, initialSubscriptionTableCall, isFilterOrSearchActive, isActionInProgress, isEditingInProgress, isSorting, pagesWithPending]);

    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />
            <div className='main-content'>
                <div className='container-fluid'>


                    {/* Subscription Details and discounted plan details Popup */}
                    <DiscountedPlanPopup loadingGif={loadingGif} subscriptionDetails={subscriptionDetails} setSubscriptionDetails={setSubscriptionDetails} subscriptionType={subscriptionType} setSubscriptionType={setSubscriptionType} discountedPlanDetails={discountedPlanDetails} setDiscountedPlanDetails={setDiscountedPlanDetails} isSubscriptionDetailsPopupOpen={isSubscriptionDetailsPopupOpen}
                        setIsSubscriptionDetailsPopupOpen={setIsSubscriptionDetailsPopupOpen} subscriptionDetailIsLoadingFlag={subscriptionDetailIsLoadingFlag}

                    />


                    {/* Edit Quantity Modal */}
                    <Popup
                        open={editQuantityModalOpen}
                        onClose={() => { setEditQuantityModalOpen(false); }}
                        className="custom-modal custom-modal-xl custom-modal-ticket-details"
                        closeOnDocumentClick={!confirmLoadingFlag}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">

                                <div className="header">
                                    <h2>Order Confirmation - Edit Quantity</h2>
                                    <XLg
                                        size={24}
                                        className="cpointer close-icon-popup"
                                        onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                                    />
                                </div>
                                <Container fluid>
                                    <Row className="pb-3 pt-5">
                                        <Col lg={8} md={6}>
                                            <h3 className="mb-3 text-regular text-bold-strong">Customer Name: <span className="text-regular">{customerNameDefaultValue}</span></h3>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="mb-3 text-regular text-bold-strong">Cloud Service: <span className="text-regular">Google Workspace</span></h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {detailsTable &&
                                            <div className={`${styles['gws-col-edit-quantity-table']}`}>
                                                <DataTable
                                                    columns={detailsTableColumn}
                                                    data={detailsTable}
                                                    theme="solarized"
                                                    customStyles={customStylesTableSmall}
                                                    persistTableHead={false}
                                                    progressComponent={<div className={styles["loading-subscriptions"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscriptions...</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    progressPending={loading}
                                                    fixedHeader
                                                />
                                                </div>
                                            }
                                             <p className='pt-9'><span className='text-strong'>Note* :</span> Renewal price is based on current pricing. This will change if new prices are published before renewal by Google.</p>

                                        </Col>
                                    </Row>
                                    <Row className="py-3">
                                        <Col>

                                            <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="w-auto d-inline ml-3"
                                                id="inputPONumber"
                                                placeholder='Add PO Number'
                                                aria-describedby="inputPONumber"
                                                maxlength="50"
                                                value={poNumber}
                                                onChange={(e) => {
                                                    alphanumericRegex.test(e.target.value)
                                                        ? setPoNumber(e.target.value)
                                                        : console.log("")
                                                }}
                                            />
                                            <Form.Text id="poNumber" />

                                        </Col>
                                    </Row>
                                    <Row className="mt-2 mb-4">
                                        <Col md={6}>

                                            <Form.Check // prettier-ignore
                                                type="checkbox"
                                                id="termsCheckBox"
                                                label=""
                                                disabled
                                                checked={isTermsChecked}
                                                className="d-inline-block w-auto"
                                            />
                                            <Form.Label className="d-inline-block  w-auto mb-0">
                                                I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                            </Form.Label>

                                        </Col>
                                        <Col md={6}>
                                            <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                                {!confirmLoadingFlag && isTermsChecked && <>
                                                    <Button
                                                        className="px-4 mx-2"
                                                        onClick={() => { setEditQuantityModalOpen(false); }}>Cancel</Button>
                                                    <Button
                                                        className="px-4 mx-2"
                                                        onClick={UpdateEditQuantity}>
                                                        Confirm
                                                    </Button>
                                                </>
                                                }
                                                {!confirmLoadingFlag && !isTermsChecked &&
                                                    <>
                                                        <Button
                                                            className="px-4 mx-2"
                                                            onClick={() => { setEditQuantityModalOpen(false); }}>Cancel
                                                        </Button>
                                                        <Button
                                                            variant="light"
                                                            className="px-4 mx-2"
                                                            disabled
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </>
                                                }
                                                {confirmLoadingFlag &&
                                                    <>
                                                        <Button
                                                            variant="light"
                                                            disabled
                                                            className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                        >Cancel
                                                        </Button>
                                                        <Button
                                                            disabled
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                        >
                                                            Processing . . .
                                                        </Button>
                                                    </>
                                                }
                                            </div>

                                        </Col>
                                    </Row>
                                </Container>
                            </div>

                        )}
                    </Popup>

                    <Popup
                        open={modalOpen}
                        onClose={() => { setModalOpen(false); }}
                        className="custom-modal custom-modal-md custom-modal-ticket-details"
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header">
                                    <h2>Cloud Portal Details</h2>
                                    <XLg
                                        size={24}
                                        className="cpointer close-icon-popup"
                                        onClick={close}
                                    />
                                </div>
                                <Container>

                                    {additionalDetails && <Row>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Cloud Service</label>
                                            <h3 className="mb-3">Google Workspace</h3>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Customer</label>
                                            <p><strong>{additionalDetails.customerName}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Cloud Identity Id</label>
                                            <p><strong>{additionalDetails.cloudIdentityId}</strong></p>
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <label className="text-medium">Start Date</label>
                                            <p><strong>{additionalDetails.startDate}</strong></p>
                                        </Col>
                                        <Col md={12} className="mb-2">
                                            <label className="text-medium">Domain Name</label>
                                            <p className='d-flex align-items-center'><span className="text-strong long-text">{additionalDetails.domainName}</span>
                                                <span className="clicktocopy">
                                                    <span
                                                        type="button"
                                                        class="copy cpointer ml-2"
                                                        onClick={() => copyToClipboard(additionalDetails.domainName)}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                    <span className="copystatus">{copied}</span>
                                                </span>
                                            </p>
                                        </Col>
                                        <Col md={12} className="mb-2">
                                            <label className="text-medium">Admin</label>
                                            <p className="d-flex"><span className="text-strong long-text">{additionalDetails.admin}</span>

                                                <span className="clicktocopy">
                                                    <span
                                                        type="button"
                                                        class="copy cpointer ml-2"
                                                        onClick={() => copyToClipboard(additionalDetails.admin)}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                    <span className="copystatus">{copied}</span>
                                                </span>
                                            </p>
                                        </Col>
                                        <Col md={12} className="mb-2">
                                            <label className="text-medium">Portal
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Use your reseller credentials to log in.</Tooltip>}
                                                >
                                                    <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
                                                </OverlayTrigger></label>

                                            <p className='service-management-acronis-portal-link'>
                                                <Row>
                                                    <Col md={6}><a href={additionalDetails.adminConsoleUrl} target="_blank" rel="noreferrer">Admin Console <span className='external-link cpointer ml-2'></span></a> <br /></Col>
                                                    <Col md={6}><a href={additionalDetails.manageUserUrl} target="_blank" rel="noreferrer" >Manage users <span className='external-link cpointer ml-2'></span></a> <br /></Col>
                                                    <Col md={6}><a href={additionalDetails.configGwUrl} target="_blank" rel="noreferrer" >Configure Google Workspace <span className='external-link cpointer ml-2'></span></a> <br /></Col>
                                                    <Col md={6}><a href={additionalDetails.viewReportUrl} target="_blank" rel="noreferrer" >View Reports <span className='external-link cpointer ml-2'></span></a> <br /></Col>
                                                </Row>
                                            </p>
                                        </Col>

                                        <Col md={12} className="mb-2">
                                            <label className="text-medium">End Relationship</label>
                                            <Row>
                                                <a className='text-strong text-underline cpointer' onClick={() => { setSelectService(defaultServiceName); setSelectCategory(defaultCategoryName); close(); setOpen(true); }}>Cancel Entitlement <span className='external-link cpointer ml-2'></span></a>
                                            </Row>

                                        </Col>
                                    </Row>}

                                    {!additionalDetails && <Row>
                                        <Col>
                                            No details found
                                        </Col>
                                    </Row>}
                                </Container>
                            </div>
                        )}
                    </Popup>
                    <Row>
                        <Col md={6} className="justify-content-between d-flex align-items-center">
                            <label className="cpointer" onClick={() => navigate('/management-console' , { state: { resellerId: resellerId } })}>
                                <span className="back">&nbsp;</span>&nbsp;<strong><span className={`${styles['service-management-heading']}`}>Google Workspace - Service Management</span></strong>
                            </label>
                        </Col>
                        <Col md={6} className="text-right service-logo">
                            <img src={serviceLogo} style={{ width: 'auto', height: '1.875rem' }} />
                        </Col>
                    </Row>
                    <div className='service-management-azure-header mt-5'>
                        <Row className='mb-3'>
                            <Col md={3} className='mb-3'>
                                <SearchDropdown
                                    dropdownLabel="Cloud Service"
                                    formGroupId="serviceNameId"
                                    placeholder="Enter Service Name"
                                    selectDefaultValue={serviceNameDefaultValue}
                                    setSelectDefaultValue={setServiceNameDefaultValue}
                                    selectOptions={serviceData}
                                    selectOptionsFiltered={serviceNameFiltered}
                                    setSelectOptionsFiltered={setServiceNameFiltered}
                                    selectSearchValue={serviceNameSearchValue}
                                    setSelectSearchValue={setServiceNameSearchValue}
                                    updatedOptions={updatedServiceName}
                                    setOptionId={setServiceId}
                                    setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                                />
                            </Col>

                            <Col md={3} className='mb-3'>
                                <SearchDropdown
                                    dropdownLabel="Customer Name"
                                    formGroupId="customerNameId"
                                    placeholder="Enter Customer Name"
                                    selectDefaultValue={customerNameDefaultValue}
                                    setSelectDefaultValue={setCustomerNameDefaultValue}
                                    setOptionId={setCustomerId}
                                    selectOptions={customerData}
                                    selectOptionsFiltered={customerNameFiltered}
                                    setSelectOptionsFiltered={setCustomerNameFiltered}
                                    selectSearchValue={customerNameSearchValue}
                                    setSelectSearchValue={setCustomerNameSearchValue}
                                    updatedOptions={updatedCustomerName}
                                    setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                                />
                            </Col>


                            <Col md={6} className='text-right'>
                                <div class="d-flex justify-content-between justify-content-md-end align-items-center h-100">
                                    <label class="pt-0 label-sm mr-3">Subscriptions
                                        <span class="circle-bg label-sm">
                                            <strong class="label-sm">
                                                {additionalDetails ? additionalDetails.subscriptionLicenseCount : 0}
                                            </strong>
                                        </span>
                                    </label>

                                    <label class="pt-0 label-sm mr-3">Add-Ons
                                        <span class="circle-bg label-sm">
                                            <strong class="label-sm">
                                                {additionalDetails ? additionalDetails.addonsLicenseCount : 0}
                                            </strong>
                                        </span>
                                    </label>

                                    {initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="light" onClick={() => setModalOpen(true)}>Cloud Portal Details</Button>}
                                    {!initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="secondary" disabled >Cloud Portal Details</Button>}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="service-management-azure-body user-management-console-main-div mb-5">
                        <div className="mgmt-console-table-row">
                            <div className="my-3 d-flex">
                                <Col className={`p-0 ${styles['service-management-table']}`}>
                                    <div className={`${styles['service-management-table-inner']} ${styles['table-details']} details-box`}>
                                        <div className="my-0 table-heading align-items-center row">
                                            <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                                <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">
                                                Subscriptions {!emptyUser && !emptyUserFilters && !emptyUserSearch && !loading && initialSubscriptionTableCall && <span className='pl-1 text-muted text-large'>({totalRows})</span>}
                                                {!loading && !provisionRefreshIconFlag && initialSubscriptionTableCall && <span className='refresh ml-2' onClick={() => { setScrollToDivTop(true); clearExistingData(); GetAdditionalDetails(); setPageNo(1); GetTableData(1, pageSize, searchValueTable, status, planType, billingTerm, sortCol, sortDir); }}></span>}
                                                </h5>
                                            </Col>
                                           {((!resellerId || resellerId?.length==0) && !isCloudAdmin) && <Col className="my-1 d-md-none d-block text-right mr-1">
                                                <Button onClick={() => navigate("/cloud-provision", {
                                                    state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                })}>Buy Subscription</Button>
                                            </Col>}
                                            {/* Filter and Search Box for Desktop and Tablet */}
                                            <Col className="d-none d-md-block">
                                                <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                    {!serverErrorUser && !emptyUser && initialSubscriptionTableCall &&
                                                        <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative pr-4">
                                                            {!searchIconFlag &&
                                                                <span className="mx-3">&nbsp;</span>
                                                            }
                                                            {!searchIconFlag && !emptyUserFilters &&
                                                                <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                            }
                                                            {searchIconFlag && !emptyUserFilters &&
                                                                <InputGroup className="search-input-box search-input-box-xl">
                                                                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                        <span className="searchgrey cpointer"
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </InputGroup.Text>
                                                                    <Form.Control
                                                                        placeholder="Search Plan Name"
                                                                        aria-label="Search Plan Name"
                                                                        aria-describedby="basic-addon2"
                                                                        className="search-input-box-input"
                                                                        value={searchValueTable}
                                                                        onChange={(e) => setSearchValueTable(e.target.value)}
                                                                        ref={searchInputRef}
                                                                    />
                                                                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                        onClick={() => {
                                                                            setSearchValueTable("");
                                                                            setSearchIconFlag(!searchIconFlag);
                                                                        }}>
                                                                        <span className="closebtn">
                                                                            &nbsp;
                                                                        </span>
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            }
                                                            {!filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                                setShowFilterBox(!showFilterBox);
                                                            }}></span>}
                                                            {filterFlag && !emptyUserSearch && initialSubscriptionTableCall && <span className="filter-black-active cpointer" 
                                                            onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                            }}
                                                            ></span>}
                                                            {(resellerId?.length == 0 && !isCloudAdmin) && <Button className="ml-3 px-4" onClick={() => navigate("/cloud-provision", {
                                                                state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                            })}>Buy Subscription</Button>}
                                                        </Col>}
                                                </Row>
                                            </Col>


                                            {/* Filter and Search Box for Mobile */}
                                            <Col className="d-md-none d-block mt-2 px-3" md={12}>
                                                <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                    {!serverErrorUser && !emptyUser && initialSubscriptionTableCall && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">

                                                        <InputGroup className="search-input-box">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search Plan Name"
                                                                aria-label="Search Plan Name"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                                ref={searchInputRef}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>

                                                        {!filterFlag && !emptyUserSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                        }}></span>}
                                                        {filterFlag && !emptyUserSearch && initialSubscriptionTableCall && <span className="filter-black-active cpointer" 
                                                        onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                            }}
                                                        ></span>}

                                                    </Col>}
                                                </Row>
                                            </Col>
                                        </div>

                                        {
                                            !emptyUserSearch && showFilterBox &&
                                            <GWSFilterBox GetTableData={GetTableData} pageNo={pageNo} setPageNo={setPageNo} setEmptyUserFilters={setEmptyUserFilters}
                                                pageSize={pageSize} searchValueTable={searchValueTable} sortCol={sortCol} sortDir={sortDir}
                                                setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag} setFilterApplied={setFilterApplied}
                                                status={status} setStatus={setStatus} subscriptionTypeData={subscriptionTypeData} statusData={statusData} billingTypeData={billingTypeData}
                                                billingType={billingTerm} setBillingType={setBillingTerm} subscriptionType={planType} setSubscriptionType={setPlanType} setScrollToDivTop={setScrollToDivTop}
                                                setIsAutoRefreshEnabled={setIsAutoRefreshEnabled} setIsFilterOrSearchActive={setIsFilterOrSearchActive} clearExistingData={clearExistingData}
                                                setPagesWithPending={setPagesWithPending}
                                                setLoadedPages={setLoadedPages}
                                            />
                                        }
                                        {
                                            upgradeActionPopupModalOpen &&
                                            <GWSUpgrade subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId}
                                                upgradeActionPopupModalOpen={upgradeActionPopupModalOpen} setUpgradeActionPopupModalOpen={setUpgradeActionPopupModalOpen} Logout={Logout}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                                                setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            downgradeActionPopupModalOpen &&
                                            <GWSDowngrade subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId}
                                                downgradeActionPopupModalOpen={downgradeActionPopupModalOpen} setDowngradeActionPopupModalOpen={setDowngradeActionPopupModalOpen} Logout={Logout}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                                                setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {editQuantityActionPopupModalOpen &&
                                            <GWSEditQuantity editQuantityActionPopupModalOpen={editQuantityActionPopupModalOpen} setEditQuantityActionPopupModalOpen={setEditQuantityActionPopupModalOpen} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable} Logout={Logout}
                                                subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} columnsSubscriptionTable={columnsSubscriptionTable} setColumnsSubscriptionTable={setColumnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                                                setTableSubscriptionTable={setTableSubscriptionTable} minQuantity={minQuantity} maxQuantity={maxQuantity} editQuantityActionsValue={editQuantityActionsValue} setEditQuantityActionsValue={setEditQuantityActionsValue} newQuantity={newQuantity} setNewQuantity={setNewQuantity} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage}
                                                setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            changePaymentPlanActionPopupModalOpen &&
                                            <GWSChangePaymentPlan subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName}
                                                changePaymentPlanActionPopupModalOpen={changePaymentPlanActionPopupModalOpen} setChangePaymentPlanActionPopupModalOpen={setChangePaymentPlanActionPopupModalOpen} Logout={Logout}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} setUpBillingActionFlag={setUpBillingActionFlag}
                                                setSubscriptionTableAPIData={setSubscriptionTableAPIData} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            renewalSettingsActionPopupModalOpen &&
                                            <GWSRenewalSettings subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName} Logout={Logout}
                                                renewalSettingsActionPopupModalOpen={renewalSettingsActionPopupModalOpen} setRenewalSettingsActionActionPopupModalOpen={setRenewalSettingsActionActionPopupModalOpen}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage}
                                                setSubscriptionTableAPIData={setSubscriptionTableAPIData} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            changeStatusActionPopupModalOpen &&
                                            <GWSChangeStatus subscriptionTableAPIData={subscriptionTableAPIData} setSubscriptionTableAPIData={setSubscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName}
                                                changeStatusActionPopupModalOpen={changeStatusActionPopupModalOpen} setChangeStatusActionPopupModalOpen={setChangeStatusActionPopupModalOpen} Logout={Logout}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage}
                                                setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            trialToPaidActionPopupModalOpen &&
                                            <GWSTrialToPaid subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName} Logout={Logout}
                                                trialToPaidActionPopupModalOpen={trialToPaidActionPopupModalOpen} setTrialToPaidActionPopupModalOpen={setTrialToPaidActionPopupModalOpen}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage}
                                                setSubscriptionTableAPIData={setSubscriptionTableAPIData} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            purchaseAddOnsActionPopupModalOpen &&
                                            <GWSPurchaseAddOns purchaseAddOnsActionPopupModalOpen={purchaseAddOnsActionPopupModalOpen} setPurchaseAddOnsActionPopupModalOpen={setPurchaseAddOnsActionPopupModalOpen} Logout={Logout}
                                                subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} columnsSubscriptionTable={columnsSubscriptionTable} setColumnsSubscriptionTable={setColumnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable}
                                                setTableSubscriptionTable={setTableSubscriptionTable} minQuantity={minQuantity} maxQuantity={maxQuantity} editQuantityActionsValue={editQuantityActionsValue} setEditQuantityActionsValue={setEditQuantityActionsValue} newQuantity={newQuantity} setNewQuantity={setNewQuantity} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                customerId={customerId} setSubscriptionTableAPIData={setSubscriptionTableAPIData} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        <Container fluid className="mb-5 p-0">
                                            <Row>
                                                <Col>
                                                    <div className={`table-responsive csp-gws-actions-table ${styles['gws-service-table']}`}>
                                                        {serverErrorUser && !loading &&
                                                            <div className={styles["no-data-subscription"]}>
                                                                <img src={EmptyDataTableFilterSearch} className="empty-user-management-table-svg" />
                                                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                            </div>
                                                        }
                                                        {emptyUserSearch && !loading && !emptyUserFilters && initialSubscriptionTableCall && !emptyUser &&
                                                            <div className={styles["no-data-subscription"]}>
                                                                <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                                <h2 className="mt-4 mb-3">No Matching Subscriptions Found.</h2>
                                                            </div>
                                                        }
                                                        {emptyUserFilters && !loading && initialSubscriptionTableCall && !emptyUser &&
                                                            <div className={styles["no-data-user"]}>
                                                                <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                                <h2 className="mt-4 mb-3">No Data Found.</h2>
                                                            </div>
                                                        }
                                                        {emptyUser && !loading && initialSubscriptionTableCall &&
                                                            <div className={styles["no-data-user"]}>
                                                                <img src={EmptyServiceProvision} className="empty-customers-table-svg" />
                                                                <h2 className="mt-4 mb-3">No Subscriptions in this Cloud Service.</h2>
                                                                {((!resellerId || resellerId?.length==0) && !isCloudAdmin) && <>
                                                                <p className="mb-3">Please click on Buy Subscriptions to Add Subscriptions</p>
                                                                <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                                                    navigate("/cloud-provision", {
                                                                        state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                                    })
                                                                }}>Buy Subscription</Button>
                                                                </>}
                                                            </div>
                                                        }
                                                        {!initialSubscriptionTableCall && initialSubscriptionTableCall != null && !serverErrorUser && !emptyUser &&
                                                            <div className={styles["no-data-user"]}>
                                                                <img src={EmptyServiceSubscription} className="empty-customers-table-svg" />
                                                                <h2 className="mt-4 mb-3">Selected Cloud Service is not provisioned</h2>
                                                                <p className="mb-3">No subscriptions are currently available for this cloud service.</p>
                                                                {((!resellerId || resellerId?.length==0) && !isCloudAdmin) && <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                                                    navigate("/cloud-provision", {
                                                                        state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                                    })
                                                                }}>Provision Cloud Service</Button>}
                                                            </div>
                                                        }
                                                        {!serverErrorUser && !emptyUser && !emptyUserFilters && !emptyUserSearch && initialSubscriptionTableCall &&
                                                            <LazyTable
                                                                d={d}
                                                                table={table}
                                                                customStyles={customStyles}
                                                                loading={infinityLoading}
                                                                pageNo={pageNo}
                                                                totalRows={totalRows}
                                                                Expandable={ExpandableTable}
                                                                handlePerRowsChange={handlePerRowsChange}
                                                                customerNameDefaultValue={customerNameDefaultValue}
                                                                GetAdditionalDetails={GetAdditionalDetails}
                                                                resellerId={resellerId}
                                                                handlePageChange={handlePageChange}
                                                                styles={styles}
                                                                handleLazyCalls={handleLazyCalls}
                                                                serverErrorUser={serverErrorUser}
                                                                emptyUser={emptyUser}
                                                                emptyUserFilters={emptyUserFilters}
                                                                emptyUserSearch={emptyUserSearch}
                                                                initialSubscriptionTableCall={initialSubscriptionTableCall}
                                                                setScrollToDivTop={setScrollToDivTop}
                                                                scrollToDivTop={scrollToDivTop}
                                                            />
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GWServiceManagement