import React, { useEffect, useState } from 'react'
import styles from './summaryWidgets.module.scss'
import { Button, Col, Row } from 'react-bootstrap'
import { GET_SUMMARY_WIDGETS } from '../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../Utils/API';

const SummaryWidgets = (props) => {
  const [summaryWidget, setSummaryWidget] = useState(null);
  const [dayPassed, setDayPassed] = useState(false);
  const [paidStatus, setPaidStatus] = useState(null);
  // Header Authorization for General API's
  const config = {
    headers: {
      "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
    },
  };

  function checkDatePassed(dateStr) {
    const dateParts = dateStr.split('/');
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[0], 10);

    const date = new Date(year, month, day);
    const currentDate = new Date();

    if (date.getTime() < currentDate.getTime()) {
      setDayPassed(true);
    } else {
      setDayPassed(false);
    }
  }

  // Function to call all Customer count data
  async function GetSummaryWidget() {
    await api.get(`${GET_SUMMARY_WIDGETS}?requestedResellerCode=${props.requestedResellerCode}`, config)
      .then(resp => {
        // console.log("resp.data", resp.data);
        let localPaidStatus = "true"
        resp.data.map((data) => {
          if (data.widgetTitle === "Next Payment Date") {
            if (data.data.length > 8) {
              checkDatePassed(data.data);
            }
          }
          if(data.widgetTitle === "Outstanding Amount" || data.widgetTitle === "Overdue Amount"){
            if (parseFloat(data.data) > 0) {
              localPaidStatus = "false"
            }
          }
        })
        localPaidStatus == "false" ? setPaidStatus(false) : setPaidStatus(true);
        setSummaryWidget(resp.data);
      })
      .catch(error => {
        if (error?.status == 401 || error?.response?.status == 401) {
          // console.log("Error 401");
          props.Logout();
        }
        else {
        }
      });
  };

  useEffect(() => {
    GetSummaryWidget();
  }, [])

  useEffect(() => {
    if(props.requestedResellerCode != "")
    GetSummaryWidget();
  }, [props.requestedResellerCode])

  return (
    <Row className='my-3'>
      {
        summaryWidget && summaryWidget.map((widget) => {
          if (widget.paymentType == null) {
            return (
              <Col md={2} xs={5} className={`${styles["widget-body"]} px-3 mx-2 mr-4`}>
                <span>{widget.widgetTitle} </span>
                <h3 className={widget.widgetTitle === "Overdue Amount" ? `${styles["widget-body-yellow"]} ${styles["active-customer-padding-top"]}` : `${styles["widget-body-green"]} ${styles["active-customer-padding-top"]}`}>{parseFloat(widget.data) >= 0 ? `$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((parseFloat(widget.data)).toFixed(2))}` : `-$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format((Math.abs(parseFloat(widget.data))).toFixed(2))}`}</h3>
              </Col>
            )
          }
          else {
            return (
              <Col md={2} xs={5} className={`${styles["widget-body"]} px-3 mx-2 mr-4`}>
                <span>{widget.widgetTitle}</span>
                <Row>
                  <Col md={5} lg={5}>
                    <h3 className={dayPassed ? `${styles["widget-body-default"]} text-danger pt-1` : `${styles["widget-body-default"]} pt-1`}>{widget.data}</h3>
                  </Col>
                  <Col md={12} lg={7} className='text-right'>
                    <span className={`${styles["sub-description"]} text-small mb-0`}><span className="credit-card mr-1">&nbsp;</span>{widget.paymentType}</span>
                  </Col>
                </Row>
              </Col>
            )
          }
        })
      }
      {paidStatus!==null && props.requestedResellerCode ==  "" && <Col md={2} xs={10} className='d-flex align-items-center'>
        {!paidStatus ? <Button className="mx-2 px-4 btn btn-lg"
            onClick={()=>window.open(`${localStorage.getItem("b2b_navigation")}Invoice-Payment?orderType=Cloud`, '_blank', 'noopener,noreferrer')}
        >
            Pay Now
        </Button> :
        <Button
            className="btn btn-border-light btn-lg mx-2 px-4"
            variant="light"
            disabled
        >
            Pay Now
        </Button>}
      </Col>}
    </Row>
  )
}

export default SummaryWidgets