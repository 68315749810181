import { NavDropdown, Form } from "react-bootstrap";
import { useRef } from "react";

const ResellerNavdropdown = ({
    formGroupId,
    placeholder,
    selectOptions,
    selectDefaultValue,
    setSelectDefaultValue,
    setOptionId,
    selectOptionsFiltered,
    setSelectOptionsFiltered,
    selectSearchValue,
    setSelectSearchValue,
    updatedOptions,
    setResellerIdChangedFlag
}) => {

    const searchBoxValue = useRef();

    // Priority On Change Function
    const selectValueChange = (e) => {
        if(setResellerIdChangedFlag){
            setResellerIdChangedFlag(true);  
        }
        setSelectDefaultValue(e.target.getAttribute("value"));
        setOptionId(e.target.id);
    }

    // OnBlur Event for Serch Input
    const clearSearchBox = () => {
        setSelectSearchValue('');
        setSelectOptionsFiltered(selectOptions);
    }

    // const handleChange = (e) => {
    //     setSelectSearchValue(e.target.value);
    //     if (e.target.value.length > 2) {
    //         selectOptions.filter(option => {

    //             if (option.name){
    //             if ((option.name).toLowerCase().includes(e.target.value.toLowerCase())) {
    //                 updatedOptions.push(option);

    //             } if (updatedOptions.length > 0) {
    //                 setSelectOptionsFiltered(updatedOptions);
    //             } else {
    //                 setSelectOptionsFiltered([{ "name": "No data available" }]);
    //             }
    //      } })
    //     } else {

    //         setSelectOptionsFiltered(selectOptions);
    //     }
    // }
    const handleChange = (e) => {
        if(setResellerIdChangedFlag){
          setResellerIdChangedFlag(true);  
        }
        setSelectSearchValue(e.target.value);
        const inputValue = e.target.value.toLowerCase();
        const filteredOptions = selectOptions.filter(option => {
            // Check for both ID and name
            if (option.id.toLowerCase().includes(inputValue) 
                || option.name.toLowerCase().includes(inputValue)    ///comment it later
            ) {
                return true;
            }
            return false;
        });
    
        // Update the filtered options based on the input value
        if (inputValue.length > 2) {
            if (filteredOptions.length > 0) {
                setSelectOptionsFiltered(filteredOptions);
            } else {
                setSelectOptionsFiltered([{ id: "No data available", name: "No data available" }]);
            }
        } else {
            setSelectOptionsFiltered(selectOptions);
        }
    }
    

    return (
        <>
            <Form.Group
                className="form-group col-md-12 my-2 mt-5 mb-0"
                controlId={formGroupId}
            >
                <NavDropdown title={selectDefaultValue} id="cloud-service-dropdown"
                    className="servcies-dropdown" onToggle={clearSearchBox}>
                    <Form.Control
                        type="text"
                        value={selectSearchValue}
                        ref={searchBoxValue}
                        placeholder={placeholder}
                        maxlength="50"
                        onChange={e => {
                            console.log("in handle change")
                           handleChange(e);
                        }}
                    />
                    {selectOptionsFiltered && selectOptionsFiltered.map((option) => {
                        return (
                            <>
                                <NavDropdown.Item
                                    key={option.id}
                                    id={option.id}
                                    data-id={option.integrationCode}
                                    value={option.name}
                                    className={selectDefaultValue === option.name ? "selected" : ""}
                                    onClick={selectValueChange}>
                                    {option.name}
                                </NavDropdown.Item>
                            </>
                        );
                    })}
                </NavDropdown>
            </Form.Group>
        </>
    );
}

export default ResellerNavdropdown;