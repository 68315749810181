import { useEffect, useRef, useState, useCallback } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Styles/App.scss";


// Anonymous User Screen Import
import Footer from './Layout/Footer';
import CustomerNavbar from "./Layout/Navbar";

// Authenticated User Screen Import
import { useNavigate } from "react-router-dom";
import CmsDetailsState from "./Context/CmsDetails/CmsDetailsSate";

// Microsoft Auth Login
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';

import ReactGA from "react-ga4";
import { loginRequest } from './authConfig';
import ContactUsDrawer from "./GlobalComponents/ContactUsDrawer";
import FeedbackDrawer from "./GlobalComponents/FeedbackDrawer";
import CookiePopup from "./Modules/Anonymous/CookiePopup";
import MaintainancePage from "./Modules/Anonymous/MaintainancePage";
import AppRoutes from "./Routes";
import NotificationsPopup from "./Modules/Customer/DashBoard/LocalComponents/NotificationsPopup";
import LoginTermsAndCondition from "./Modules/Customer/DashBoard/LocalComponents/LoginTermsAndConditions";
// import { acquireTokenSilently } from './tokenRefresh';

const App = ({ instance, isCusLoggedIn }) => {

  const navigate = useNavigate();   // React Router v6

  const isAuthenticated = useIsAuthenticated();

  // UseStates Defination
  const [customerIsAuthenticated, setCustomerIsAuthenticated] = useState(false);               // Customer is authenticated Flag
  const [profileInitialCredentialChange, setProfileInitialCredentialChange] = useState(false); // Keeping Count of Profile Image initials change
  const [activeLocalKey, setActiveLocalKey] = useState("default");                             // navigation bar default value
  const [pageNotFound, setPageNotFound] = useState(false);                                     // Page not found Flag
  const [open, setOpen] = useState(false);                                               // Contact Us Toggle Drawer

  const [hookRecentNotifications, setHookRecentNotifications] = useState(false);                                               // Contact Us Toggle Drawer
  const [hookNotifications, setHookNotifications] = useState(false); 

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  }; 
  
  const [openFeedback, setOpenFeedback] = useState(false);                                               // Feedback Toggle Drawer

  const toggleDrawerFeedback = (newOpen) => () => {
    setOpenFeedback(newOpen);
  };

  const [formEndPoint, setFormEndPoint] = useState(null);

  const [termsAndConditionPopupFlag, setTermsAndConditionPopupFlag] = useState(false);
  const [notificationsPopupFlag, setNotificationsPopupFlag] = useState(false);
  const [triggerNotificationsPopup, setTriggerNotificationsPopup] = useState(false);

  const [selectService, setSelectService] = useState("Select Service");   // Select Service
  const [selectCategory, setSelectCategory] = useState("Select Category"); // Select Category
  const [integrationContactUsFormFlag, setIntegrationContactUsFormFlag] = useState(false); 
  const [comingFromIntegration, setComingFromIntegration] = useState(false);

  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [maintainancePageActive, setMaintainancePageActive] = useState(false);
 
  const [isUserActive, setIsUserActive] = useState(true);
  const lastActivityTimeRef = useRef(Date.now());
  const tokenRefreshIntervalRef = useRef(null);

  useEffect(() => {
    let value= false;

    if(isAuthenticated===true) {
      // navigate("/synnex-cloud/dashboard")
      // console.log("Logedddd innnnnnnnnnnnn");
      value=true;
    }

    // localStorage.clear();
    // let value = localStorage.getItem("customerIsAuthenticated");
    if (value) {
      // navigate('/dashboard');
      setCustomerIsAuthenticated(value); // Defining default value that customer is signed in or not
    }
  }, [isAuthenticated]);

  const setAuthentication = (value) => {
    // localStorage.setItem("customerIsAuthenticated", value);
    setCustomerIsAuthenticated(value); // Defining value that customer is signed in or not after change in useState
  };

    // Logout Function on error 410
    function Logout() {
      setCustomerIsAuthenticated(false);
      if(localStorage.getItem("access_token") && localStorage.getItem("access_token").length>0){
        if (customerIsAuthenticated) { navigate("/") };
        localStorage.clear();
        instance.logoutRedirect();
      }
      else{
          // console.log("Instance :",instance)
          instance.loginRedirect(loginRequest).catch((error) => console.log(error));
      } 
  }

  const handleUserActivity = useCallback(() => {
    lastActivityTimeRef.current = Date.now();
    if (!isUserActive) {
      setIsUserActive(true);
  //  console.log("User now active");
      startTokenRefresh();
    }
  }, [isUserActive]);

  const handleGetUserData = useCallback(async () => {
    if (!isUserActive) return;
    try {
      const account = instance.getAllAccounts()[0];
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: ["https://ncmstagesynnexpartner.onmicrosoft.com/tasks-api/tasks.read"],
        account: account,
        forceRefresh: true,
      });
      // console.log("Token refreshed::::::", tokenResponse.accessToken);
      localStorage.setItem("access_token", tokenResponse.accessToken);
    } catch (err) {
      // console.error("Token refresh failed:", err);
    }
  }, [instance, isUserActive]);

  const startTokenRefresh = useCallback(() => {
      if (tokenRefreshIntervalRef.current) {
      clearInterval(tokenRefreshIntervalRef.current);
      }
      tokenRefreshIntervalRef.current = setInterval(() => {
      handleGetUserData();
      }, 15 * 60 * 1000); 
  }, [handleGetUserData]);

  useEffect(() => {
      const checkUserActivity = () => {
        // console.log("checkuseractivity");
      const currentTime = Date.now();
      const inactiveTime = currentTime - lastActivityTimeRef.current;
      if (inactiveTime > 14 * 60 * 1000) { 
        if (isUserActive) {
          setIsUserActive(false);
          // console.log("User is now inactive");
          if (tokenRefreshIntervalRef.current) {
            clearInterval(tokenRefreshIntervalRef.current);
            tokenRefreshIntervalRef.current = null;
          }
          localStorage.removeItem("navigation_link");
          localStorage.clear();
          navigate("/");
          instance.logoutRedirect();
         }
         }
         else{
          // console.log("Active User")
         }
    };

    const activityInterval = setInterval(checkUserActivity, 2 * 60 * 1000); 

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      clearInterval(activityInterval);
      if (tokenRefreshIntervalRef.current) {
        clearInterval(tokenRefreshIntervalRef.current);
      }
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [handleUserActivity]);

  useEffect(() => {
    if (customerIsAuthenticated && isUserActive) {
      handleGetUserData();
      startTokenRefresh();
    }

    return () => {
      if (tokenRefreshIntervalRef.current) {
        clearInterval(tokenRefreshIntervalRef.current);
      }
    };
  }, [customerIsAuthenticated, isUserActive, handleGetUserData, startTokenRefresh]);


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "Cloud Marketplace" });
  }, [window.location.pathname])

  return (
      <>
        <CmsDetailsState>
          {!maintainancePageActive && <div className={!maintainancePageActive ? "main-page" : "d-none"}>
            <AuthenticatedTemplate>
              <>
                <CustomerNavbar setCustomerIsAuthenticated={setAuthentication} activeLocalKey={activeLocalKey} setActiveLocalKey={setActiveLocalKey} hookRecentNotifications={hookRecentNotifications} setHookRecentNotifications={setHookRecentNotifications}
                  profileInitialCredentialChange={profileInitialCredentialChange} setProfileInitialCredentialChange={setProfileInitialCredentialChange} toggleDrawer={toggleDrawer} toggleDrawerFeedback={toggleDrawerFeedback} setHookNotifications={setHookNotifications} setComingFromIntegration={setComingFromIntegration} Logout={Logout} />
                {/* Navbar visible to logged in Customer */}
              </>
            </AuthenticatedTemplate>
            <ContactUsDrawer toggleDrawer={toggleDrawer} open={open} setOpen={setOpen} setSelectService={setSelectService} selectService={selectService} selectCategory={selectCategory} setSelectCategory={setSelectCategory} setIntegrationContactUsFormFlag={setIntegrationContactUsFormFlag}  comingFromIntegration={comingFromIntegration} Logout={Logout} />
            <FeedbackDrawer toggleDrawer={toggleDrawerFeedback} open={openFeedback} setOpen={setOpenFeedback} Logout={Logout}/>
            <LoginTermsAndCondition  customerIsAuthenticated={customerIsAuthenticated} termsAndConditionPopupFlag={termsAndConditionPopupFlag} setTermsAndConditionPopupFlag={setTermsAndConditionPopupFlag} Logout={Logout} triggerNotificationsPopup={triggerNotificationsPopup} setTriggerNotificationsPopup={setTriggerNotificationsPopup}/>
            <NotificationsPopup termsAndConditionPopupFlag={termsAndConditionPopupFlag} setTermsAndConditionPopupFlag={setTermsAndConditionPopupFlag} Logout={Logout}  notificationsPopupFlag={notificationsPopupFlag} setNotificationsPopupFlag={setNotificationsPopupFlag} triggerNotificationsPopup={triggerNotificationsPopup} setTriggerNotificationsPopup={setTriggerNotificationsPopup} />
            <AppRoutes customerIsAuthenticated={customerIsAuthenticated} setAuthentication={setAuthentication} instance={instance} setFormEndPoint={setFormEndPoint} cookieAccepted={cookieAccepted} setPageNotFound={setPageNotFound} open={open} termsAndConditionPopupFlag={termsAndConditionPopupFlag} setTermsAndConditionPopupFlag={setTermsAndConditionPopupFlag} Logout={Logout} toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} setIntegrationContactUsFormFlag={setIntegrationContactUsFormFlag} integrationContactUsFormFlag={integrationContactUsFormFlag} setComingFromIntegration={setComingFromIntegration} setOpen={setOpen} setActiveLocalKey={setActiveLocalKey} setHookRecentNotifications={setHookRecentNotifications} hookNotifications={hookNotifications} setHookNotifications={setHookNotifications} />
            {!pageNotFound && <Footer formEndPoint={formEndPoint} setOpen={setOpen} customerIsAuthenticated={customerIsAuthenticated} />} {/* Footer for all screen */}
            <CookiePopup setCookieAccepted={setCookieAccepted} customerIsAuthenticated={customerIsAuthenticated} Logout={Logout} /> 
          </div>}
          <div className={maintainancePageActive ? "" : "d-none"}>
            <MaintainancePage setMaintainancePageActive={setMaintainancePageActive}/>
          </div>
        </CmsDetailsState>
      </>
  );
}

export default App;