import React, { useContext, useEffect, useState } from 'react'
import { Accordion, AccordionContext, Button, Card, Col, Container, useAccordionButton, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import styles from './CSPTrialToPaid.module.scss';
import DataTable from 'react-data-table-component';
import { api } from '../../../../../../../../Utils/API';
import { CSP_TRIAL_TO_PAID_GET_PLANS, CSP_TRIAL_TO_PAID_POST_PLANS } from '../../../../../../../../Utils/GlobalConstants';
import Table from 'react-bootstrap/Table';
import CustomNumberSimpleInput from '../../../../../../../../GlobalComponents/CustomNumberSimpleInput';
import EmptyDataTableFilterSearch from '../../../../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg'
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif"

const CSPTrialToPaid = ({ subscriptionTableAPIData, planName, subscriptionId, trialToPaidActionPopupModalOpen, setTrialToPaidActionPopupModalOpen,
    columnsSubscriptionTable, tableSubscriptionTable, setToastSuccess, setToastError, setToastMessage, setActionCompletedRefreshSubscriptionTable, Logout,
  setIsActionInProgress,
    setIsAutoRefreshEnabled  }) => {

    const alphanumericRegex = /^[a-zA-Z0-9-]{0,50}$/; // RegEx for PO Number
    const [loading, setLoading] = useState(false);                                                   //loading state while loading table data
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);                                      // order confirmation terms and condition tick check    
    let TrialToPaidColumnNameStep1 = ["", "Plan", "Commitment Term (Billing Frequency)",
        "RRP (Inc GST)", "Reseller Price (Ex GST)",];                                                //stepper step 1 data columns
    const [trialToPaidDetails, setTrialToPaidDetails] = useState(null);                              //data containing all details of step 1 in stepper 
    let TrialToPaidColumnNameStep2 = ["Plan", "Commitment Term (Billing Frequency)",                                        //stepper step 2 data columns
        "RRP (Inc GST)", "Reseller Price (Ex GST)", "Quantity", "Sub-Total Price"];
    const [trialToPaidTableStep2, setTrialToPaidTableStep2] = useState(null);                        // react data table for stepper step 2
    const [trialToPaidColumnsStep2, setTrialToPaidColumnsStep2] = useState(null);                    // columns for react data table stepper step 2
    let TrialToPaidColumnNameStep3 = ["Plan", "Commitment Term (Billing Frequency)",                                        // stepper step 3 columns
        "Unit Price", "Quantity", "Sub-Total Price"];
    const [trialToPaidTableStep3, setTrialToPaidTableStep3] = useState(null);                        // react data table for stepper step 3
    const [trialToPaidColumnsStep3, setTrialToPaidColumnsStep3] = useState(null);                    // columns for react data table stepper step 3
    const [trialToPaidNewQuantity, setTrialToPaidNewQuantity] = useState(null);                      // new edit quantity change
    const [poNumber, setPoNumber] = useState("");                                                    // storing PO number in order confirmation
    const [defaultTrialToPaid, setDefaultTrialToPaid] = useState(null);                              //stepper step1 which contain selected data
    const [allSelectedDetails, setAllSelectedDetails] = useState(null);                              // data stored in step 1
    const [confirmTrialToPaidLoadingFlag, setConfirmTrialToPaidLoadingFlag]                          // Trial To Paid loading state
        = useState(false);
    const [emptyDataError, setEmptyDataError] = useState(false);                                     // to check empty data error-204
    const [serverError, setServerError] = useState(false);                                           // to check server error-400

    //Stepper
    const steps = ['Select SKU', 'Add Quantity'];                                                    // all steps of stepper
    const [activeStep, setActiveStep] = useState(0);                                                 // active step in stepper

    //buttons for Trial To Paid stepper in step 1 and step 2
    const [buttonCheckstep1, setButtonCheckstep1] = useState(false);
    const [buttonCheckstep2, setButtonCheckstep2] = useState(false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    //Trial to paid get api step 1 details
    async function GetTrialToPaidDetails(subscriptionId) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        await api.get(`${CSP_TRIAL_TO_PAID_GET_PLANS}?subscriptionId=${subscriptionId}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyDataError(true);
                }
                // handle success
                else {
                    setEmptyDataError(false);
                    setServerError(false);
                    setTrialToPaidDetails(resp.data.plans);
                }
            })
            .catch(error => {
                if (error.response.status == 400) {
                    setServerError(true);
                }
                else if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    //Trial to paid step 2
    function TrialToPaidStep2() {
        if (allSelectedDetails) {
            // Column Edited Quantity
            let f = [];
            f.push
                (
                    {
                        "RRP (Inc GST)": `$${allSelectedDetails.rrpInclusiveTax}`,
                        "Reseller Price (Ex GST)": `$${allSelectedDetails.price}`,
                        "Commitment Term (Billing Frequency)": allSelectedDetails.billingTerm,
                        "Plan": allSelectedDetails.planName,
                        "Quantity":
                            <div className='d-flex flex-wrap my-2'>
                                <CustomNumberSimpleInput
                                    defaultValue={subscriptionTableAPIData.quantity}
                                    min={allSelectedDetails.flavor.minQuantity}
                                    max={allSelectedDetails.flavor.maxQuantity}
                                    setUpdatedQuanity={setTrialToPaidNewQuantity}
                                />
                            </div>
                        ,
                        "Sub-Total Price": `$${trialToPaidNewQuantity ? ((allSelectedDetails.price) * trialToPaidNewQuantity).toFixed(2) : ((allSelectedDetails.price)) * subscriptionTableAPIData.quantity}`
                    }
                );
            setTrialToPaidTableStep2(f);         // Setting Table Data      
            let d = []
            TrialToPaidColumnNameStep2.map((td) => {
                if (td === "Sub-Total Price") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        allowOverflow: true,
                    })
                }
                else if (td === "Commitment Term (Billing Frequency)") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">
                            {row[`${td}`]}</div>,
                        left: true,
                        grow: 3,
                        allowOverflow: true,
                    })
                }
                else if (td === "Quantity") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        grow: 1,
                        allowOverflow: true,
                    })
                }
                else if (td === "Reseller Price (Ex GST)"){
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        grow: 3,
                        allowOverflow: true,
                    })
                }
                else if (td === "RRP (Inc GST)") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        grow: 2,
                        allowOverflow: true,
                    })
                }
                else {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        grow: 1,
                        allowOverflow: true,
                    })
                }
            })
            setTrialToPaidColumnsStep2(d);
        }
    }

    //Order confirmation table last step for Trial to Paid 
    function TrialToPaidOrderConfirmationTable() {
        if (subscriptionTableAPIData) {
            // Column Trial to Paid plan step 3
            let f = [];
            f.push
                (
                    {
                        "Unit Price": `$${allSelectedDetails.price}`,
                        "Plan": allSelectedDetails.planName,
                        "Quantity": trialToPaidNewQuantity,
                        "Commitment Term (Billing Frequency)": allSelectedDetails.billingTerm,
                        "Sub-Total Price": `$${trialToPaidNewQuantity ? ((allSelectedDetails.price) * trialToPaidNewQuantity).toFixed(2) : ((allSelectedDetails.price)) * subscriptionTableAPIData.quantity}`
                    }
                );
            setTimeout(() => {
                setTrialToPaidTableStep3(f);         // Setting Table Data
            }, 50);
            let d = []
            TrialToPaidColumnNameStep3.map((td) => {
                if (td === "Unit Price" || td === "Sub-Total Price" || td === "Quantity") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        right: true,
                        allowOverflow: true,
                    })
                }
                else if (td === "Commitment Term (Billing Frequency)") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">
                            {row[`${td}`]}</div>,
                        left: true,
                        grow: 2,
                        allowOverflow: true,
                    })
                }
                else {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        left: true,
                        grow: 1,
                        allowOverflow: true,
                    })
                }
            })
            setTrialToPaidColumnsStep3(d);     // Setting Column Data  
        }
    }

    //post api for Trial To paid confirmation
    async function TrialToPaidOrderConfirmation() {
        setConfirmTrialToPaidLoadingFlag(true);

        const data = {
            "planId": allSelectedDetails.planId,
            "subscriptionId": parseInt(subscriptionId),
            "quantity": parseInt(trialToPaidNewQuantity),
            "poNumber": poNumber
        };
        const configFile = {
            headers: {
                "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
            },
        };
        await api.post(CSP_TRIAL_TO_PAID_POST_PLANS, data, configFile)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmTrialToPaidLoadingFlag(false);
                    setServerError(false);
                    setEmptyDataError(false);
                    setTrialToPaidActionPopupModalOpen(false);

                    setAllSelectedDetails(null);
                    setToastMessage("The Trial Plan has been successfully converted to a Paid Plan.");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setActionCompletedRefreshSubscriptionTable(true);
                    setIsActionInProgress(false);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setConfirmTrialToPaidLoadingFlag(false);
                setToastMessage("Trial Plan could not be converted to Paid Plan at the moment. Try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
                setIsActionInProgress(true);
                console.log("auto refresh 1");
                setIsAutoRefreshEnabled(false);

            })
    };

    // STEPPER

    //last step back button
    const handleBackOrderConfirmation = () => {
        setButtonCheckstep2(false);
        setPoNumber("");

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setTrialToPaidNewQuantity(subscriptionTableAPIData.quantity);
    };

    const handleNextStep1 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        TrialToPaidStep2();
    };
    const handleNextStep2 = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonCheckstep2(true);
        TrialToPaidOrderConfirmationTable();
    };

    // Toggle the state so Table changes to clearSelectedRows are triggered
    const handleClearRows = () => {
        setDefaultTrialToPaid();
        setButtonCheckstep1(false);
        setButtonCheckstep2(false);
    }

    // accordion
    function CustomToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = activeEventKey === eventKey;
        return (
            <span
                className='pt-1 align-content-center'
                onClick={decoratedOnClick}
            >{children}
                {isCurrentEventKey ? (
                    <span className='up-accordion' />
                ) : (
                    <span className='down-accordion' />
                )}
            </span>
        );
    }

    // React Data Table Custom Styles
    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
                borderBottom: '0.5px solid #DDDDDD',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    // React Data Table Custom Styles for trial to paid
    const customStylesTrialToPaid = {
        rows: {
            style: {
                minHeight: '60px',                         // override the row height
                borderBottom: '0.5px solid #DDDDDD',
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem',                      // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa'
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    useEffect(() => {
        GetTrialToPaidDetails(subscriptionId);
    }, [])

    useEffect(() => {
        TrialToPaidStep2();
    }, [trialToPaidNewQuantity])

    return (
        <div>
            <Popup
                className="custom-modal custom-modal-xl"
                open={trialToPaidActionPopupModalOpen}
                onClose={() => { setTrialToPaidActionPopupModalOpen(false); setAllSelectedDetails(null); setServerError(false); setEmptyDataError(false); }}
                closeOnDocumentClick={!(confirmTrialToPaidLoadingFlag || buttonCheckstep2 || buttonCheckstep1)}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header">
                            <h2>Trial to Paid</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong"
                                onClick={() => { if (!confirmTrialToPaidLoadingFlag) close() }}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className={`upgrade-modal-height ${styles['modal-width']}`}>
                            {/* accordion details  */}
                            <Accordion defaultActiveKey="1">
                                <Card className={`${styles['accordion-changePaymentPlanGWS']}`}>
                                    <Row className={`ml-1 mr-1 ${styles['tab-header']}`}>
                                        <Col md={5} className="mt-2 mb-2">
                                            <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}>Current Plan :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.planName : "Loading"}</span>
                                        </Col>
                                        <Col md={6} className="mt-2 mb-2">
                                            <span className={`${styles['tab-header']}`} ><span className={`text-strong ${styles['tab-header']}`}> Current Subscription ID :</span> {subscriptionTableAPIData ? subscriptionTableAPIData.vendorSubscriptionId : "Loading"}</span>
                                        </Col>
                                        <Col md={1} className='text-right'><CustomToggle className='text-left text-center' eventKey="0"></CustomToggle></Col>
                                    </Row>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div className={`table-responsive  ${styles['gws-subsription-details-table']}`}>
                                                {tableSubscriptionTable &&
                                                    <DataTable
                                                        columns={columnsSubscriptionTable}
                                                        data={tableSubscriptionTable}
                                                        theme="solarized"
                                                        customStyles={customStyles}
                                                        persistTableHead={false}
                                                        noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                        width="100%"
                                                        fixedHeaderScrollHeight="60vh"
                                                        progressPending={loading}
                                                        progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details . . .</p></div>}
                                                        fixedHeader
                                                    />
                                                }
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                            {/* Stepper */}
                            <Box sx={{ width: '100%' }} className='pt-6'>
                                <Row >
                                    <Col md={3}></Col>
                                    <Col md={6}
                                        className={`${styles['button-color-manage-gws']}`}>
                                        {activeStep !== steps.length && (
                                            <Stepper activeStep={activeStep}>
                                                {steps.map((label, index) => {
                                                    const stepProps = {};
                                                    const labelProps = {};
                                                    return (
                                                        <Step key={label} {...stepProps}>
                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        )}
                                    </Col>
                                    <Col md={3}></Col>
                                </Row>
                                {buttonCheckstep2 && <div className={`${styles['gws-changePaymentPlanGWS-core-step2']}`}>
                                    <h2 className='mb-3'>Order Confirmation</h2>
                                    {trialToPaidTableStep3 &&
                                        <DataTable
                                            columns={trialToPaidColumnsStep3}
                                            data={trialToPaidTableStep3}
                                            theme="solarized"
                                            customStyles={customStyles}
                                            persistTableHead={false}
                                            noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                            width="100%"
                                            fixedHeaderScrollHeight="31vh"
                                            progressPending={loading}
                                            progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                            fixedHeader
                                        />
                                    }
                                    <Row>
                                        <Col>
                                            <Form.Label className="w-auto mt-12" htmlFor="inputPONumber">PO Number:</Form.Label>
                                            <Form.Control
                                                placeholder="Add PO Number"
                                                type="text"
                                                className="w-auto d-inline ml-3"
                                                id="inputPONumber"
                                                aria-describedby="inputPONumber"
                                                value={poNumber}
                                                maxlength="50"
                                                onChange={(e) => {
                                                    alphanumericRegex.test(e.target.value)
                                                        ? setPoNumber(e.target.value)
                                                        : console.log("")
                                                }}
                                            />
                                            <Form.Text id="poNumber" />

                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={6}>
                                            <Form.Check                                     // prettier-ignore
                                                type="checkbox"
                                                id="termsCheckBox"
                                                label=""
                                                disabled
                                                checked={isTermsChecked}
                                                className="d-inline-block w-auto"
                                            />
                                            <p className="d-inline-block  w-auto mb-0">
                                                I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                            </p>

                                        </Col>
                                        <Col md={6}>
                                            <div className="btn-wrapper text-right d-md-block d-none">
                                                {isTermsChecked && <>
                                                    {!confirmTrialToPaidLoadingFlag && <Button
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBackOrderConfirmation}
                                                        sx={{ mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>}
                                                    {!confirmTrialToPaidLoadingFlag && <Button
                                                        className="px-4 mx-2"
                                                        onClick={() => {
                                                            TrialToPaidOrderConfirmation();
                                                        }}
                                                    >
                                                        Confirm
                                                    </Button>}
                                                    {confirmTrialToPaidLoadingFlag && <Button
                                                        className="px-4 mx-2"
                                                        disabled
                                                    >Back</Button>}
                                                    {confirmTrialToPaidLoadingFlag &&
                                                        <Button
                                                            disabled
                                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                        >
                                                            Processing . . .
                                                        </Button>}
                                                </>
                                                }
                                                {!isTermsChecked &&
                                                    <>
                                                        <Button
                                                            className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                            variant="light"
                                                            disabled={activeStep === 0}
                                                            onClick={handleBackOrderConfirmation}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Back
                                                        </Button>

                                                        <Button
                                                            variant="light"
                                                            className="px-4 mx-2"
                                                            disabled
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>}
                                <React.Fragment>
                                    {serverError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                        </div>
                                    }
                                    {emptyDataError &&
                                        <div className={styles["no-data-cloud-invoice"]}>
                                            <img src={EmptyDataTableFilterSearch} className="mt-4 empty-cloud-invoice-table-svg" />
                                            <h2 className="mt-4 mb-3">This subscription can't be converted</h2>
                                        </div>
                                    }
                                    {activeStep === 0 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        {!serverError && !emptyDataError &&
                                            <div className={`table-responsive pt-3 ${styles['gws-changePaymentPlanGWS-core']}`}>
                                                {!trialToPaidDetails &&
                                                    <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans . . .</p></div>}
                                                {trialToPaidDetails &&
                                                    <Table responsive="md">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                {TrialToPaidColumnNameStep1.map(th => {
                                                                    return (<th className={(th === "RRP (Inc GST)" || th === "Reseller Price (Ex GST)") ? "text-right" : ""}>
                                                                        {th}
                                                                    </th>)
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                trialToPaidDetails.map((td) => {
                                                                    return (<tr>
                                                                        <td><input type='radio'
                                                                            id={td.planId}
                                                                            name="upgradeOptionsStep2"
                                                                            checked={defaultTrialToPaid === (td.planId)}
                                                                            onChange={() => {
                                                                                setDefaultTrialToPaid(td.planId);
                                                                                setButtonCheckstep1(true);
                                                                                setAllSelectedDetails(td);
                                                                            }}
                                                                        />
                                                                        </td>
                                                                        <td>{td.planName}</td>
                                                                        <td>{td.billingTerm}</td>
                                                                        <td className='text-right'>${td.rrpInclusiveTax}</td>
                                                                        <td className='text-right'>${td.price}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>}
                                    </Typography>}
                                    {activeStep === 1 && <Typography sx={{ mt: 2, mb: 1 }}>
                                        <div className={`table-responsive pt-3 ${styles['gws-changePaymentPlanGWS-core-step2']}`}>
                                            {trialToPaidTableStep2 &&
                                                <DataTable
                                                    columns={trialToPaidColumnsStep2}
                                                    data={trialToPaidTableStep2}
                                                    theme="solarized"
                                                    customStyles={customStylesTrialToPaid}
                                                    persistTableHead={false}
                                                    noDataComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans. . .</p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="31vh" progressPending={loading}
                                                    progressComponent={<div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plans. . .</p></div>}
                                                    fixedHeader
                                                />
                                            }
                                        </div>
                                    </Typography>}
                                    {/* buttons for Trial to Paid for stepper */}
                                    {!serverError && !emptyDataError &&
                                        <div className='text-right pt-4'>
                                            {activeStep === 0 &&
                                                <React.Fragment>
                                                    {buttonCheckstep1 && <Button
                                                        onClick={handleClearRows}
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light" sx={{ mr: 1 }}>
                                                        Clear
                                                    </Button>}
                                                    {!buttonCheckstep1 && <Button className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled>
                                                        Clear
                                                    </Button>}
                                                    {!buttonCheckstep1 && <Button onClick={handleNextStep1}
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled
                                                    >
                                                        Next
                                                    </Button>}
                                                    {buttonCheckstep1 && <Button onClick={handleNextStep1}
                                                    >
                                                        Next
                                                    </Button>}
                                                </React.Fragment>
                                            }
                                            {activeStep === 1 &&
                                                <React.Fragment>
                                                    <Button
                                                        className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        sx={{ mr: 1 }}
                                                    > Back  </Button>
                                                    {<Button onClick={handleNextStep2}
                                                    >
                                                        Proceed
                                                    </Button>}
                                                </React.Fragment>
                                            }
                                        </div>}
                                </React.Fragment>

                            </Box>
                        </Container>
                    </div>
                )}
            </Popup>
        </div>
    )
}

export default CSPTrialToPaid